import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { CategoryType } from '../../@types/v2/categories';

// ----------------------------------------------------------------------

type CategoriesStateType = {
  isLoading: boolean;
  error: boolean;
  sortBy: string | null;
  data: CategoryType[];
  currentCategory: CategoryType;
  isSubmitting: boolean;
};

const initialState: CategoriesStateType = {
  isLoading: false,
  error: false,
  sortBy: null,
  data: [] as CategoryType[],
  currentCategory: {} as CategoryType,
  isSubmitting: false,
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    setSubmitting(state, action) {
      state.isSubmitting = action.payload;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // SET CATEGORY
    setCategories(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    // CURRENT CATEGORY
    setCurrentCategory(state, action) {
      state.isLoading = false;
      state.currentCategory = action.payload;
    },
    // SORT & FILTER VARIANT
    sortByCategory(state, action) {
      state.sortBy = action.payload;
    },
  },
});

export const useCategories = (): CategoriesStateType =>
  useSelector(
    (RootState: { categories: CategoriesStateType }) => RootState.categories,
  );

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  stopLoading,
  hasError,
  setCategories,
  setCurrentCategory,
  sortByCategory,
  setSubmitting,
} = slice.actions;
