import { createSlice } from '@reduxjs/toolkit';
import { ExtraTypeV2 } from '../../@types/v2/extras';
import { useSelector } from '../store';

// ----------------------------------------------------------------------

type ExtraStateType = {
  isLoading: boolean;
  error: boolean;
  sortBy: string | null;
  data: ExtraTypeV2[];
  extra: ExtraTypeV2 | null;
};

const initialState: ExtraStateType = {
  isLoading: false,
  error: false,
  sortBy: null,
  data: [] as ExtraTypeV2[],
  extra: null,
};

// ----------------------------------------------------------------------

const extraSlice = createSlice({
  name: 'extras',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // SET EXTRAS
    setExtras(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    // SET EXTRA
    setExtra(state, action) {
      state.isLoading = false;
      state.extra = action.payload;
    },
    // SORT EXTRAS
    sortByExtras(state, action) {
      state.sortBy = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const useExtras = (): ExtraStateType =>
  useSelector((RootState) => RootState.extras);

// Reducer
export default extraSlice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  setExtras,
  reset,
  setExtra,
  sortByExtras,
} = extraSlice.actions;
