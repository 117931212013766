import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import {
  TableGroupDetailType,
  TableItemType,
} from '../../@types/v2/tablegroup';

// ----------------------------------------------------------------------

type TableGroupStateType = {
  error: boolean;
  loading: {
    main: boolean;
    activation: boolean;
  };
  tableGroups: TableGroupDetailType[];
  currentTableGroup: TableGroupDetailType;
  activeFloorPlan: TableItemType[];
};

const initialState: TableGroupStateType = {
  error: false,
  loading: {
    main: false,
    activation: false,
  },
  tableGroups: [],
  currentTableGroup: {} as TableGroupDetailType,
  activeFloorPlan: [] as TableItemType[],
};

const slice = createSlice({
  name: 'table-groups',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state, action) {
      switch (action.payload) {
        case 'main':
          state.loading.main = true;
          break;
        case 'activation':
          state.loading.activation = true;
          break;
        default:
          break;
      }
    },

    // STOP LOADING
    stopLoading(state, action) {
      switch (action.payload) {
        case 'main':
          state.loading.main = false;
          break;
        case 'activation':
          state.loading.activation = false;
          break;
        default:
          break;
      }
    },

    hasError(state, action) {
      state.error = action.payload;
    },

    // SET ORDER DETAIL DATA
    setTableGroups: (state, action) => {
      state.tableGroups = action.payload;
      state.loading.main = false;
      state.loading.activation = false;
    },

    // SET ORDER HISTORY
    setTableGroup(state, action) {
      state.currentTableGroup = action.payload;
      state.loading.main = false;
      state.loading.activation = false;
    },

    // SET ACTIVE FLOOR PLAN DATA
    setActiveFloorPlan(state, action) {
      state.activeFloorPlan = action.payload;
    },

    // UPDATE TABLE POSITION
    setTablePosition(state, action) {
      const newTablesData = state.activeFloorPlan.map((table) => {
        if (table._id === action.payload._id) {
          return {
            ...table,
            ...action.payload.table,
          };
        }

        return table;
      });

      state.activeFloorPlan = newTablesData;
    },
  },
});

export const useTableGroups = (): TableGroupStateType =>
  useSelector(
    (RootState: { tableGroups: TableGroupStateType }) => RootState.tableGroups,
  );

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  stopLoading,
  setTableGroup,
  setTableGroups,
  setActiveFloorPlan,
  setTablePosition,
} = slice.actions;
