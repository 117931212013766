import type { OrderType } from '../../@types/v2/orders/list';

export const isSubwaySandwich = (item: OrderType['items'][0]) => {
  const categoryIds = [
    '655bdefec638612b5cb93622',
    '663b860e0e3645ec1c61b2ba',
    '663d83ef6084a5526c730211',
  ];

  if (typeof item.product_categories[0].category === 'string') {
    if (categoryIds.includes(item.product_categories[0].category)) {
      return true;
    }
  } else if (
    '_id' in item.product_categories[0].category &&
    categoryIds.includes(item.product_categories[0].category._id as string)
  ) {
    return true;
  }

  return false;
};
