/* eslint-disable import/no-duplicates */
import '../locales/i18n';
import 'simplebar-react/dist/simplebar.min.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

// fullcalendar
import '@fullcalendar/common/main.min.css';
import '@fullcalendar/daygrid/main.min.css';

import { ReactElement, ReactNode, useEffect } from 'react';
import { NextPage } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { AppProps } from 'next/app';
import { Provider as ReduxProvider } from 'react-redux';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { setDefaultOptions } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';
import mixpanel from 'mixpanel-browser';
import { PrinterProvider } from '../contexts/PrinterContext';
import { PrintQueueProvider } from '../contexts/PrintQueueContext';
import GrowthBookUpsellProvider from '../components/growthbook/GrowthBookUpsellProvider';
import {
  SettingsProvider,
  SettingsValueProps,
  ThemeSettings,
} from '../components/settings';
import SnackbarProvider from '../components/snackbar';
import { AuthProvider } from '../auth/propel-auth/PropelAuthContext';
import { store } from '../redux/store';
import { CollapseDrawerProvider } from '../contexts/CollapseDrawerContext';
import ThemeProvider from '../theme';
import { StyledChart } from '../components/chart';
import ProgressBar from '../components/progress-bar/ProgressBar';
import MotionLazyContainer from '../components/animate/MotionLazyContainer';
import { AxiosProvider } from '../contexts/AxiosContext';
import { DashboardApiProvider } from '../contexts/DashboardApiContext';

// Check our docs
// https://docs-minimals.vercel.app/authentication/ts-version

// ----------------------------------------------------------------------
setDefaultOptions({ locale: enGB });

if (typeof window !== 'undefined') {
  mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN as string, {
    track_pageview: 'full-url',
    persistence: 'localStorage',
    debug: process.env.NODE_ENV !== 'production',
    api_host: 'https://api-eu.mixpanel.com',
  });
}

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

interface MyAppProps extends AppProps {
  settings: SettingsValueProps;
  Component: NextPageWithLayout;
}

export default function MyApp(props: MyAppProps) {
  const { Component, pageProps } = props;

  const getLayout = Component.getLayout ?? ((page) => page);

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      // Mixpanel tracking for page view
      mixpanel.track_pageview();
    };

    // Subscribe to route changes
    router.events.on('routeChangeComplete', handleRouteChange);

    // Unsubscribe from route changes on cleanup
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <title>Upsell</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>

      <AuthProvider>
        <GrowthBookUpsellProvider>
          <AxiosProvider serverUrl={process.env.NEXT_PUBLIC_API_ENDPOINT}>
            <DashboardApiProvider
              serverUrl={process.env.NEXT_PUBLIC_NEW_API_ENDPOINT as string}
            >
              <ReduxProvider store={store}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={enGB}
                >
                  <CollapseDrawerProvider>
                    <SettingsProvider>
                      <MotionLazyContainer>
                        <ThemeProvider>
                          <ThemeSettings>
                            <SnackbarProvider>
                              <PrintQueueProvider>
                                <PrinterProvider>
                                  <StyledChart />
                                  <ProgressBar />
                                  {getLayout(<Component {...pageProps} />)}
                                </PrinterProvider>
                              </PrintQueueProvider>
                            </SnackbarProvider>
                          </ThemeSettings>
                        </ThemeProvider>
                      </MotionLazyContainer>
                    </SettingsProvider>
                  </CollapseDrawerProvider>
                </LocalizationProvider>
              </ReduxProvider>
            </DashboardApiProvider>
          </AxiosProvider>
        </GrowthBookUpsellProvider>
      </AuthProvider>
    </>
  );
}
