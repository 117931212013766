import React, {
  createContext,
  useContext,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { useAuthInfo } from '@propelauth/react';
import { createDashboardClient } from '@monorepo/dashboard-api/client';
import { usePropelAuthContext } from '../auth/useAuthContext';

export type DashboardClient = ReturnType<typeof createDashboardClient>;

export const DashboardApiContext = createContext<DashboardClient | null>(null);

type Props = {
  children: React.ReactNode;
  serverUrl: string;
};

export const DashboardApiProvider = ({ children, serverUrl }: Props) => {
  const authInfo = useAuthInfo();
  const authInfoRef = useRef(authInfo);
  const { currentOrgName } = usePropelAuthContext();

  useEffect(() => {
    authInfoRef.current = authInfo;
  }, [authInfo]);

  const dashboardClient = useMemo(() => {
    const accessToken = authInfoRef.current.accessToken;
    if (!currentOrgName || !accessToken) {
      return null;
    }
    return createDashboardClient({
      orgName: currentOrgName,
      serverUrl,
      token: accessToken,
    });
  }, [currentOrgName, serverUrl, authInfoRef]);

  if (!dashboardClient) {
    return null;
  }

  return (
    <DashboardApiContext.Provider value={dashboardClient}>
      {children}
    </DashboardApiContext.Provider>
  );
};

export const useDashboardApi = () => {
  const dashboardInstance = useContext(DashboardApiContext);
  if (!dashboardInstance) {
    throw new Error(
      'useDashboardApi must be used within a DashboardApiProvider',
    );
  }
  return dashboardInstance;
};
