import { useEffect, createContext, ReactNode, useState, useMemo } from 'react';
import { useSnackbar } from '../components/snackbar';
import { OrderPrintOnDraw } from '../utils/printing';
import { OrderType } from '../@types/v2/orders/list';

type ProviderProps = {
  children: ReactNode;
};

type ContextProps = {
  pushOrderToQueue: (order: OrderType) => void;
};

const initialState: ContextProps = {
  pushOrderToQueue: () => null,
};

export const PrintQueueContext = createContext(initialState);

export const PrintQueueProvider = ({ children }: ProviderProps) => {
  const [printingQueue, setPrintingQueue] = useState<OrderType[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // when printing queue is updated, print the first order
    if (printingQueue.length !== 0) {
      const toBePrinted = printingQueue[0];

      const interval = setTimeout(() => {
        enqueueSnackbar(`#${toBePrinted.order_number} queued`, {
          variant: 'info',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          autoHideDuration: 1500,
          disableWindowBlurListener: true,
          action: (key: any) => <OrderPrintOnDraw order={toBePrinted} />,
        });

        // remove the first order from printing queue
        setPrintingQueue((prev) => prev.slice(1));
      }, 1500);

      return () => {
        clearTimeout(interval);
      };
    }
    return () => null;
  }, [printingQueue]);

  const pushOrderToQueue = (order: OrderType) => {
    setPrintingQueue((prev) => [...prev, order]);
  };

  const contextValue = useMemo(
    () => ({
      pushOrderToQueue,
    }),
    [pushOrderToQueue],
  );

  return (
    <PrintQueueContext.Provider value={contextValue}>
      {children}
    </PrintQueueContext.Provider>
  );
};
