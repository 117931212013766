export enum AdminRole {
  Developer = 'Developer',
  Owner = 'Owner',
  Manager = 'Manager',
  BranchManager = 'Branch Manager',
  Chef_HeadKitchen = 'Chef/Head Kitchen',
  PrepCook = 'Prep Cook',
  Cashier = 'Cashier',
  Waitstaff_Server = 'Waitstaff/Server',
  Bartender = 'Bartender',
}

export const ADMIN_ROLES = Object.values(AdminRole);

export function validateRole(role: string) {
  if (ADMIN_ROLES.includes(role as AdminRole)) {
    return role as AdminRole;
  }
  return null;
}

export enum DashboardPermissions {
  OrderRead = 'dashboard::order::read',
  OrderWrite = 'dashboard::order::write',
  ReportsReadLimited = 'dashboard::reports::read::limited',
  ReportsReadFull = 'dashboard::reports::read::full',
  CategoryRead = 'dashboard::category::read',
  CategoryWrite = 'dashboard::category::write',
  CouponRead = 'dashboard::coupon::read',
  CouponWrite = 'dashboard::coupon::write',
  DealsRead = 'dashboard::deals::read',
  DealsWrite = 'dashboard::deals::write',
  DiscountRead = 'dashboard::discount::read',
  DiscountWrite = 'dashboard::discount::write',
  ExtrasRead = 'dashboard::extras::read',
  ExtrasWrite = 'dashboard::extras::write',
  GiftcardRead = 'dashboard::giftcard::read',
  GiftcardWrite = 'dashboard::giftcard::write',
  KioskRead = 'dashboard::kiosk::read',
  KioskWrite = 'dashboard::kiosk::write',
  ProductRead = 'dashboard::product::read',
  ProductWrite = 'dashboard::product::write',
  TagRead = 'dashboard::tag::read',
  TagWrite = 'dashboard::tag::write',
  VariantRead = 'dashboard::variant::read',
  VariantWrite = 'dashboard::variant::write',
  CustomersRead = 'dashboard::customers::read',
  CustomersWrite = 'dashboard::customers::write',
  CompanyRead = 'dashboard::company::read',
  CompanyWrite = 'dashboard::company::write',
  OrderDisplayRead = 'dashboard::order_display::read',
  OrderDisplayWrite = 'dashboard::order_display::write',
  TeamManagementRead = 'dashboard::team_management::read',
  TeamManagementWrite = 'dashboard::team_management::write',
  TableManagementRead = 'dashboard::table_management::read',
  TableManagementWrite = 'dashboard::table_management::write',
  PaymentWrite = 'dashboard::payment::write',
  StoreRead = 'dashboard::store::read',
  StoreWrite = 'dashboard::store::write',
  WidgetWrite = 'dashboard::widget::write',
  WidgetRead = 'dashboard::widget::read',
  InventoryRead = 'dashboard::inventory::read',
  InventoryWrite = 'dashboard::inventory::write',
  IntegrationRead = 'dashboard::integration::read',
  IntegrationWrite = 'dashboard::integration::write',
  IngredientRead = 'dashboard::ingredient::read',
  IngredientWrite = 'dashboard::ingredient::write',
  LoyaltyRead = 'dashboard::loyalty::read',
  LoyaltyWrite = 'dashboard::loyalty::write',
}

export enum PosPermissions {
  CheckoutWrite = 'pos::checkout::write',
  OrderRead = 'pos::order::read',
  OrderWrite = 'pos::order::write',
  KitchenRead = 'pos::kitchen::read',
  KitchenWrite = 'pos::kitchen::write',
  CustomersRead = 'pos::customers::read',
  CustomersWrite = 'pos::customers::write',
  CompanyRead = 'pos::company::read',
  CompanyWrite = 'pos::company::write',
  DrawerRead = 'pos::drawer::read',
  DrawerWrite = 'pos::drawer::write',
  StoreRead = 'pos::store::read',
  StoreWrite = 'pos::store::write',
}

export enum KitchenPermissions {
  Read = 'kitchen::read',
  Write = 'kitchen::write',
}

// Full Permissions -----------------------------------------
export type FullPermissions =
  | DashboardPermissions
  | PosPermissions
  | KitchenPermissions;

const ownerPermissions: FullPermissions[] = [
  DashboardPermissions.CategoryRead,
  DashboardPermissions.CategoryWrite,
  DashboardPermissions.CouponRead,
  DashboardPermissions.CouponWrite,
  DashboardPermissions.CustomersRead,
  DashboardPermissions.CustomersWrite,
  DashboardPermissions.CompanyRead,
  DashboardPermissions.CompanyWrite,
  DashboardPermissions.DealsRead,
  DashboardPermissions.DealsWrite,
  DashboardPermissions.DiscountRead,
  DashboardPermissions.DiscountWrite,
  DashboardPermissions.ExtrasRead,
  DashboardPermissions.ExtrasWrite,
  DashboardPermissions.GiftcardRead,
  DashboardPermissions.GiftcardWrite,
  DashboardPermissions.InventoryRead,
  DashboardPermissions.InventoryWrite,
  DashboardPermissions.KioskRead,
  DashboardPermissions.KioskWrite,
  DashboardPermissions.OrderDisplayRead,
  DashboardPermissions.OrderDisplayWrite,
  DashboardPermissions.OrderRead,
  DashboardPermissions.OrderWrite,
  DashboardPermissions.PaymentWrite,
  DashboardPermissions.ProductRead,
  DashboardPermissions.ProductWrite,
  DashboardPermissions.ReportsReadFull,
  DashboardPermissions.ReportsReadLimited,
  DashboardPermissions.StoreRead,
  DashboardPermissions.StoreWrite,
  DashboardPermissions.TagRead,
  DashboardPermissions.TagWrite,
  DashboardPermissions.TeamManagementRead,
  DashboardPermissions.TeamManagementWrite,
  DashboardPermissions.TableManagementRead,
  DashboardPermissions.TableManagementWrite,
  DashboardPermissions.VariantRead,
  DashboardPermissions.VariantWrite,
  DashboardPermissions.WidgetRead,
  DashboardPermissions.WidgetWrite,
  DashboardPermissions.IntegrationRead,
  DashboardPermissions.IntegrationWrite,
  DashboardPermissions.IngredientRead,
  DashboardPermissions.IngredientWrite,
  DashboardPermissions.LoyaltyRead,
  DashboardPermissions.LoyaltyWrite,
  KitchenPermissions.Read,
  KitchenPermissions.Write,
  PosPermissions.CheckoutWrite,
  PosPermissions.CustomersRead,
  PosPermissions.CustomersWrite,
  PosPermissions.CompanyRead,
  PosPermissions.CompanyWrite,
  PosPermissions.DrawerRead,
  PosPermissions.DrawerWrite,
  PosPermissions.KitchenRead,
  PosPermissions.KitchenWrite,
  PosPermissions.OrderRead,
  PosPermissions.OrderWrite,
  PosPermissions.StoreRead,
  PosPermissions.StoreWrite,
];

export const ADMIN_PERMISSIONS: Record<AdminRole, FullPermissions[]> = {
  Owner: ownerPermissions,
  Manager: [
    DashboardPermissions.CategoryRead,
    DashboardPermissions.CategoryWrite,
    DashboardPermissions.CouponRead,
    DashboardPermissions.CouponWrite,
    DashboardPermissions.CustomersRead,
    DashboardPermissions.CustomersWrite,
    DashboardPermissions.CompanyRead,
    DashboardPermissions.DealsRead,
    DashboardPermissions.DealsWrite,
    DashboardPermissions.DiscountRead,
    DashboardPermissions.DiscountWrite,
    DashboardPermissions.ExtrasRead,
    DashboardPermissions.ExtrasWrite,
    DashboardPermissions.GiftcardRead,
    DashboardPermissions.GiftcardWrite,
    DashboardPermissions.InventoryRead,
    DashboardPermissions.InventoryWrite,
    DashboardPermissions.KioskRead,
    DashboardPermissions.KioskWrite,
    DashboardPermissions.OrderDisplayRead,
    DashboardPermissions.OrderDisplayWrite,
    DashboardPermissions.OrderRead,
    DashboardPermissions.OrderWrite,
    DashboardPermissions.ProductRead,
    DashboardPermissions.ProductWrite,
    DashboardPermissions.ReportsReadFull,
    DashboardPermissions.ReportsReadLimited,
    DashboardPermissions.StoreRead,
    DashboardPermissions.StoreWrite,
    DashboardPermissions.TagRead,
    DashboardPermissions.TagWrite,
    DashboardPermissions.TeamManagementRead,
    DashboardPermissions.TeamManagementWrite,
    DashboardPermissions.TableManagementRead,
    DashboardPermissions.TableManagementWrite,
    DashboardPermissions.VariantRead,
    DashboardPermissions.VariantWrite,
    DashboardPermissions.WidgetRead,
    DashboardPermissions.WidgetWrite,
    DashboardPermissions.IngredientRead,
    DashboardPermissions.IngredientWrite,
    DashboardPermissions.LoyaltyRead,
    DashboardPermissions.LoyaltyWrite,
    KitchenPermissions.Read,
    KitchenPermissions.Write,
    PosPermissions.CheckoutWrite,
    PosPermissions.CustomersRead,
    PosPermissions.CustomersWrite,
    PosPermissions.CompanyRead,
    PosPermissions.DrawerRead,
    PosPermissions.DrawerWrite,
    PosPermissions.KitchenRead,
    PosPermissions.KitchenWrite,
    PosPermissions.OrderRead,
    PosPermissions.OrderWrite,
    PosPermissions.StoreRead,
    PosPermissions.StoreWrite,
  ],
  'Branch Manager': [
    DashboardPermissions.CategoryRead,
    DashboardPermissions.CategoryWrite,
    DashboardPermissions.CouponRead,
    DashboardPermissions.CouponWrite,
    DashboardPermissions.CustomersRead,
    DashboardPermissions.CustomersWrite,
    DashboardPermissions.CompanyRead,
    DashboardPermissions.DealsRead,
    DashboardPermissions.DealsWrite,
    DashboardPermissions.DiscountRead,
    DashboardPermissions.DiscountWrite,
    DashboardPermissions.ExtrasRead,
    DashboardPermissions.ExtrasWrite,
    DashboardPermissions.GiftcardRead,
    DashboardPermissions.GiftcardWrite,
    DashboardPermissions.InventoryRead,
    DashboardPermissions.InventoryWrite,
    DashboardPermissions.KioskRead,
    DashboardPermissions.KioskWrite,
    DashboardPermissions.OrderDisplayRead,
    DashboardPermissions.OrderDisplayWrite,
    DashboardPermissions.OrderRead,
    DashboardPermissions.OrderWrite,
    DashboardPermissions.ProductRead,
    DashboardPermissions.ProductWrite,
    DashboardPermissions.ReportsReadFull,
    DashboardPermissions.ReportsReadLimited,
    DashboardPermissions.StoreRead,
    DashboardPermissions.StoreWrite,
    DashboardPermissions.TagRead,
    DashboardPermissions.TagWrite,
    DashboardPermissions.TeamManagementRead,
    DashboardPermissions.TeamManagementWrite,
    DashboardPermissions.TableManagementRead,
    DashboardPermissions.TableManagementWrite,
    DashboardPermissions.VariantRead,
    DashboardPermissions.VariantWrite,
    DashboardPermissions.WidgetRead,
    DashboardPermissions.WidgetWrite,
    DashboardPermissions.IngredientRead,
    DashboardPermissions.IngredientWrite,
    DashboardPermissions.LoyaltyRead,
    DashboardPermissions.LoyaltyWrite,
    KitchenPermissions.Read,
    KitchenPermissions.Write,
    PosPermissions.CheckoutWrite,
    PosPermissions.CustomersRead,
    PosPermissions.CustomersWrite,
    PosPermissions.CompanyRead,
    PosPermissions.DrawerRead,
    PosPermissions.DrawerWrite,
    PosPermissions.KitchenRead,
    PosPermissions.KitchenWrite,
    PosPermissions.OrderRead,
    PosPermissions.OrderWrite,
    PosPermissions.StoreRead,
    PosPermissions.StoreWrite,
  ],
  'Chef/Head Kitchen': [
    DashboardPermissions.OrderDisplayRead,
    DashboardPermissions.OrderDisplayWrite,
    KitchenPermissions.Read,
    KitchenPermissions.Write,
  ],
  'Prep Cook': [KitchenPermissions.Read, KitchenPermissions.Write],
  Cashier: [
    PosPermissions.CheckoutWrite,
    PosPermissions.CustomersRead,
    PosPermissions.CustomersWrite,
    PosPermissions.CompanyRead,
    PosPermissions.DrawerRead,
    PosPermissions.DrawerWrite,
    PosPermissions.KitchenRead,
    PosPermissions.KitchenWrite,
    PosPermissions.OrderRead,
    PosPermissions.OrderWrite,
    PosPermissions.StoreRead,
    PosPermissions.StoreWrite,
  ],
  'Waitstaff/Server': [
    PosPermissions.CheckoutWrite,
    PosPermissions.CustomersRead,
    PosPermissions.CustomersWrite,
    PosPermissions.CompanyRead,
    PosPermissions.DrawerRead,
    PosPermissions.DrawerWrite,
    PosPermissions.KitchenRead,
    PosPermissions.KitchenWrite,
    PosPermissions.OrderRead,
    PosPermissions.OrderWrite,
    PosPermissions.StoreRead,
    PosPermissions.StoreWrite,
  ],
  Bartender: [KitchenPermissions.Read, KitchenPermissions.Write],
  Developer: ownerPermissions,
};
