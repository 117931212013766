// disabled for use with ZPL
import { format } from 'date-fns';
import type {
  ExtrasType,
  OrderDetailType,
  OrderType,
} from '../../../@types/v2/orders/list';
import { isSubwaySandwich } from '../utils';
import { PRIORITIZED_EXTRAS } from './../commons';

export type LabelSettingsType = {
  LABEL_PRINT_SKU: boolean;
};

/**
 * Displays Subway logo
 */
export const head = (order: OrderType) => {
  const orderNumber = order.secondary_order_number
    ? order.secondary_order_number
    : `#${order.order_number}`;

  return `
    ^FO20,55^GFA,1350,1350,6,004,00C,01E,03F,07F8,0FFC,0FFE,1FFE,3IF,7IF8,JFC,0FFC,::::0JFC,0KF8005,0KFC002,07JFE006,07KF002,07KF8,03KF8,03KFC,01KFC,00NF,001MF8,K07JF8,:K03JF8,::K07JF8,:K0KF8,07NF,07KFC,:07KF8,:07KF,07JFE,07JFC,07JF8,07IFE,,::003MF8,007MF8,00NF8,01NF8,03NF8,:07NF8,:0OF8,:0FFC03FC,0FF803FC,:::::0FFC03FC,0OF8,:07NF8,:03NF8,:01NF8,00NF8,007MF8,001MF8,,::07KFE,07LFE,07MF8,07MFC,:07MFE,07NF,::07NF8,:M01FF8,N0FF8,:::M01FF8,07NF8,07NF,:07MFE,:::07NF,:07NF8,M01FF8,N0FF8,:::M01FF8,07NF8,:07NF,::07MFE,:07MFC,07MF8,07LFE,07LF,,:L03F8,001FC0C03,0060218008,008019I04,01I0AI02,02I04I02,02M01,04M01,::0401803F008,04046060808,04083040808,04081040C08,:::::040FF07F808,04N08,:::::::07NF8,,::07KFE,04L0E,04L01,04M08,04M04,04M02,:04M01,:04N08,07KFE008,M01008,N0808,:::::M01008,07KFE008,04N08,04M01,:04M02,:04M04,04M08,04L03,07LFC,,:M0F8,L0607,07F8008008,040801I04,040802I02,040806I02,040804I01,040808I01,:0408100201,0408100D01,0408200881,0408201081,0408401081,0408402081,0408802081,0405804081,0403004081,04J08081,:02I010081,:01I020081,0180040081,00C0080081,00303I081,I0FC00F81F8M08001,M04002,M02004,M01008,N0818,N0C1,N042,N024,N018,O08,^FS
    ^FO80,0^GB1,352,1^FS
    ^FO85,0^GB3,352,3^FS

    ^FO80,0^GB1,352,1^FS
    ^FO85,0^GB3,352,3^FS

    ^CF0,25
    ^FO110,0^FB332,,,L^FD${orderNumber}\\&^FS
    ^CF0,20
    ^FO110,0^FB230,,,L^FDTO\\&^FS
    ^CF0,25
    ${
      order.customer &&
      order.customer.name &&
      `^FO135,0^FB230,,,L^FD${order.customer.name.slice(0, 16).toUpperCase()}\\&^FS`
    }
    ${
      order.customer &&
      order.customer.phone.phone_number &&
      `
      ^FO160,0^FB230,,,L^FD${
        order.customer && order.customer.phone.phone_number.slice(0, 4)
      } ${order.customer && order.customer.phone.phone_number.slice(4)}\\&^FS
      `
    }
    ^FO85,250^GB115,3,3^FS
    ^FO200,0^GB1,352,1^FS
    ^FO205,0^GB3,352,3^FS
  `;
};

const generateVariation = (item: OrderDetailType, xOffset = 0) => {
  const displayQuantity = isSubwaySandwich(item);

  const isNameRequiresTwoLines = item.name.length > 20;

  let label = displayQuantity
    ? ` ^CF0,25
    ^FO${235 + xOffset},0^FB332,2,,L^FD${item.name.toUpperCase()}^FS
  `
    : ` ^CF0,25
    ^FO${235 + xOffset},0^FB332,2,,L^FD${item.quantity} X ${item.name.toUpperCase()}^FS`;
  let height = 70;

  if (isNameRequiresTwoLines) {
    label += `
    ^CF0,20
    ^FO${285 + xOffset},0^FB332,1,,L^FD${item.variation.name.toUpperCase()}^FS
    `;
    height += 25;
  } else {
    label += `
    ^CF0,20
    ^FO${260 + xOffset},0^FB332,1,,L^FD${item.variation.name.toUpperCase()}^FS
    `;
  }

  return {
    label,
    height,
    nameHasTwoLines: isNameRequiresTwoLines,
  };
};

const variation = (item: OrderDetailType, xOffset = 0) => {
  const { label, height, nameHasTwoLines } = generateVariation(item, xOffset);

  let labelWithBorder = label;
  let newHeight = height;

  if (nameHasTwoLines) {
    labelWithBorder += `^FO${330 + xOffset},0^GB1,352,1^FS`;
    newHeight += 30;
  } else {
    labelWithBorder += `^FO${305 + xOffset},0^GB1,352,1^FS`;
    newHeight += 30;
  }

  return {
    label: labelWithBorder,
    height: newHeight,
  };
};

const variationExtras = (item: OrderDetailType, xOffset = 0) => {
  const { label, height, nameHasTwoLines } = generateVariation(item, xOffset);

  const { data: extrasData, lines } = displayExtras(
    item.extras,
    nameHasTwoLines ? 315 + xOffset : 290 + xOffset,
  );

  let labelWithExtras = label;
  let heightWithExtras = height;

  labelWithExtras += `${extrasData}`;

  switch (lines) {
    case 6:
      if (nameHasTwoLines) {
        labelWithExtras += `^FO${460 + xOffset},0^GB1,352,1^FS`;
        heightWithExtras += 160;
      } else {
        labelWithExtras += `^FO${435 + xOffset},0^GB1,352,1^FS`;
        heightWithExtras += 160;
      }
      break;
    case 3:
      if (nameHasTwoLines) {
        labelWithExtras += `^FO${400 + xOffset},0^GB1,352,1^FS`;
        heightWithExtras += 100;
      } else {
        labelWithExtras += `^FO${375 + xOffset},0^GB1,352,1^FS`;
        heightWithExtras += 100;
      }
      break;
    case 1:
      if (nameHasTwoLines) {
        labelWithExtras += `^FO${350 + xOffset},0^GB1,352,1^FS`;
        heightWithExtras += 50;
      } else {
        labelWithExtras += `^FO${325 + xOffset},0^GB1,352,1^FS`;
        heightWithExtras += 50;
      }
      break;
    default:
      if (nameHasTwoLines) {
        labelWithExtras += `^FO${330 + xOffset},0^GB1,352,1^FS`;
        heightWithExtras += 30;
      } else {
        labelWithExtras += `^FO${305 + xOffset},0^GB1,352,1^FS`;
        heightWithExtras += 30;
      }
      break;
  }

  return {
    label: labelWithExtras,
    height: heightWithExtras,
  };
};

const displayExtras = (extras: ExtrasType[], xOffset = 0, yOffset = 0) => {
  // sort extras based on PRIORITIZED_EXTRAS
  // then if extras excluded_items.length > 0, put it on the start of the array
  const sortedExtras = extras
    ?.slice()
    .sort(
      (a, b) =>
        PRIORITIZED_EXTRAS.indexOf(a.name) - PRIORITIZED_EXTRAS.indexOf(b.name),
    );

  const generatedExtras: string[] = [];

  sortedExtras.forEach((extra) => {
    if (extra.excluded_items.length > 0) {
      extra.excluded_items.forEach((item) => {
        if (item.include) {
          generatedExtras.push(`-${item.doubled ? ' x2 ' : ' '}${item.name}`);
        }
      });
    }

    if (extra.items.length > 0) {
      extra.items.forEach((item) => {
        if (!item.include || item.doubled) {
          generatedExtras.push(`${item.doubled ? 'x2 ' : ''}${item.name}`);
        }
      });
    }
  });

  // sort generated extas, if string contains -, put it on beginning of the array
  generatedExtras.sort(
    (a, b) => (a.includes('-') ? 0 : 1) - (b.includes('-') ? 0 : 1),
  );

  const extrasString = generatedExtras.join(', ').toUpperCase();

  let temp = '';
  let lines = 0;

  return {
    data: temp,
    lines: lines,
  };

  if (extrasString.length > 70) {
    // use 6 lines
    temp += `
      ^FO${xOffset},0^FB332,6,,L^FD${extrasString}\\&^FS
      `;
    lines = 6;
  } else if (extrasString.length > 30) {
    // use 3 lines
    temp += `
      ^FO${xOffset},0^FB332,3,,L^FD${extrasString}\\&^FS
      `;
    lines = 3;
  } else if (extrasString.length > 0) {
    // use 1 line
    temp += `
      ^FO${xOffset},0^FB332,1,,L^FD${extrasString}\\&^FS
      `;
    lines = 1;
  }

  return {
    data: `
      ${
        generatedExtras.length > 0
          ? `
        ^CF0,20
        ${temp}
      `
          : ''
      }
    `,
    lines,
  };
};

const simpleItem = (item: OrderDetailType, xOffset = 0) => {
  let lines = 1;

  if (item.name.length > 30) {
    lines = 3;
  } else if (item.name.length > 15) {
    lines = 2;
  }

  let label = `
    ^CF0,25
    ^FO${235 + xOffset},0^FB332,${lines},,L^^FD${item.quantity} X ${item.name.toUpperCase()}^FS
  `;
  let height = 75;

  if (lines === 2) {
    label += `^FO${305 + xOffset},0^GB1,352,1^FS`;
    height += 25;
  } else if (lines === 3) {
    label += `^FO${330 + xOffset},0^GB1,352,1^FS`;
    height += 50;
  } else {
    label += `^FO${280 + xOffset},0^GB1,352,1^FS`;
  }

  return {
    label,
    height,
  };
};

export const contents = (item: OrderDetailType, xOffset = 0) => {
  if (!item.type) {
    console.warn('Missing item type', item);
    return {
      label: '',
      height: 0,
    };
  }

  switch (item.type) {
    case 'variation_extras':
      return variationExtras(item, xOffset);
    case 'variation':
      return variation(item, xOffset);
    default:
      return simpleItem(item, xOffset);
  }
};

export const tail = (order: OrderType, index: number, length: number) => {
  return `
  ^FO810,0^GB1,352,1^FS
  ^FO815,0^GB3,352,3^FS
  ^CF0,25
  ^FO840,0^FB332,,,L^FD${format(new Date(order.createdAt), 'dd-MM-yy')}, ${format(
    new Date(order.createdAt),
    'HH:mm',
  )}\\&^FS
    ^FO840,20^FB332,,,R^FD${`${index} OF ${length}`}^FS
  `;
};
