import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from '../store';

// ----------------------------------------------------------------------

type DiscountStateType = {
  isLoading: boolean;
  error: boolean;
  sortBy: null;
  data: any[];
  discount: any;
};

const initialState: DiscountStateType = {
  isLoading: false,
  error: false,
  sortBy: null,
  data: [],
  discount: null,
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'discount',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET CATEGORY
    getDiscountSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    getDiscountByIdSuccess(state, action) {
      state.isLoading = false;
      state.discount = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const useDiscount = () => useSelector((RootState) => RootState.discount);

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  stopLoading,
  hasError,
  getDiscountSuccess,
  getDiscountByIdSuccess,
  reset,
} = slice.actions;
