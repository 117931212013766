import { forwardRef, useRef, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import * as Sentry from '@sentry/nextjs';
import { useSnackbar } from '../../components/snackbar';
import { waitingTimeLegacyFallback } from '../legacy-fallback';
import type { OrderType, OrderDetailType } from '../../@types/v2/orders/list';
import {
  Body,
  DeliveryAddress,
  Header,
  Note,
  UnpaidBar,
  TestBar,
  MethodBar,
  SecondaryOrderNumberBar,
} from './commons';
import { head, tail } from './label';
import {
  head as subwayHead,
  tail as subwayTail,
  contents as subwayContents,
} from './subway_label/order';
import {
  head as subwayItemHead,
  tail as subwayItemTail,
} from './subway_label/item';
import type { LabelSettingsType } from './types';

type Props = {
  order: OrderType;
};

type PropsWithCustomElements = Props & {
  ssn?: string;
};

export const PrintableOrder = forwardRef<HTMLDivElement, Props>(
  ({ order }, ref) => {
    // regex check if order.customer.name contains "Testing123" in any case
    const isTestUser = /Testing123/gi.test(order?.customer?.name);

    return (
      <div
        ref={ref}
        style={{
          // width: '80mm',
          fontFamily: 'tabela Soft',
          textTransform: 'uppercase',
        }}
        id="order"
      >
        {order && (
          <>
            {isTestUser && <TestBar />}
            <Header
              customer={order.customer}
              store={order.store}
              method={order.order_method}
              number={order.order_number}
              branch={order.branch}
              paid={!!order.date_paid}
              createdAt={order.createdAt}
              mode="order"
              waitingTime={
                order.waiting_time || waitingTimeLegacyFallback(order)
              }
              notes={order.order_notes}
              table={order.table}
            />
            {order.order_method !== 'dine-in' && (
              <MethodBar method={order.order_method} />
            )}
            {order.order_method === 'delivery' && order.shipping && (
              <DeliveryAddress shipping={order.shipping} />
            )}
            {order.order_method !== 'dine-in' && !order.date_paid && (
              <UnpaidBar />
            )}
            {order.order_notes.length > 0 && <Note notes={order.order_notes} />}
            <Body
              additionalCosts={order.additional_cost}
              currency={order.currency}
              items={order.items}
              total={order.total}
              sub_total={order.sub_total}
              discount_total={order.discount_total}
              tax_total={order.tax_total}
              paid={false}
              mode="order"
              method={order.order_method}
              tax_rates={order.tax_rates}
            />
          </>
        )}
        {order.secondary_order_number && (
          <SecondaryOrderNumberBar
            number={order.secondary_order_number}
            mode="order"
          />
        )}
      </div>
    );
  },
);

export const PrintableReceipt = forwardRef<
  HTMLDivElement,
  PropsWithCustomElements
>(({ order, ssn }, ref) => {
  // regex check if order.customer.name contains "Testing123" in any case
  const isTestUser = /Testing123/gi.test(order?.customer?.name);

  return (
    <div
      ref={ref}
      style={{
        // width: '80mm',
        fontFamily: 'tabela Soft',
        textTransform: 'uppercase',
      }}
    >
      {isTestUser && <TestBar />}
      <Header
        customer={order.customer}
        store={order.store}
        method={order.order_method}
        number={order.order_number}
        branch={order.branch}
        createdAt={order.createdAt}
        paid={false}
        mode="receipt"
        waitingTime={order.waiting_time || waitingTimeLegacyFallback(order)}
        ssn={ssn}
        notes={order.order_notes}
        table={order.table}
      />
      {!order.date_paid && <UnpaidBar />}
      <Body
        additionalCosts={order.additional_cost}
        currency={order.currency}
        items={order.items}
        total={order.total}
        sub_total={order.sub_total}
        discount_total={order.discount_total}
        tax_total={order.tax_total}
        paid={false}
        method={order.order_method}
        mode="receipt"
        tax_rates={order.tax_rates}
      />
      {order.secondary_order_number && (
        <SecondaryOrderNumberBar
          number={order.secondary_order_number}
          mode="receipt"
        />
      )}
    </div>
  );
});

PrintableOrder.displayName = 'PrintableOrder';
PrintableReceipt.displayName = 'PrintableReceipt';

export const OrderPrintOnDraw = ({ order }: Props) => {
  const printRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => {
      // console.log('[PRINT] ORDER-AFTER', order._id);
    },
    onBeforePrint: () => {
      // console.log('[PRINT] ORDER-BEFORE', order._id);
    },
    onPrintError: (errorLocation, error) => {
      enqueueSnackbar(error.message || 'Error', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
      console.error('[PRINT] ORDER-ERROR', errorLocation, error);
      Sentry.captureException(error);
    },
  });

  useEffect(() => {
    handlePrint();
  }, [order]);

  return (
    <div
      style={{
        overflow: 'hidden',
        height: '0',
        width: '0',
      }}
    >
      <PrintableOrder order={order} ref={printRef} />
    </div>
  );
};

export const ReceiptPrintOnDraw = ({ order }: Props) => {
  const printRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => {
      // console.log('[PRINT] ORDER-AFTER', order._id);
    },
    onBeforePrint: () => {
      // console.log('[PRINT] ORDER-BEFORE', order._id);
    },
    onPrintError: (errorLocation, error) => {
      enqueueSnackbar(error.message || 'Error', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
      console.error('[PRINT] RECEIPT-ERROR', errorLocation, error);
      Sentry.captureException(error);
    },
  });

  useEffect(() => {
    handlePrint();
  }, [order]);

  return (
    <div
      style={{
        overflow: 'hidden',
        height: '0',
        width: '0',
      }}
    >
      <PrintableReceipt order={order} ref={printRef} />
    </div>
  );
};

// generates label for a single item
export const generateLabelDefault = (
  order: OrderType,
  item: OrderDetailType,
  settings: LabelSettingsType,
  index: number,
  length: number,
) => {
  // init string as buffer
  let buffer = '^XA^CI28';

  buffer += head(item, settings);
  buffer += tail(order, index, length);

  // close buffer
  buffer += '^FWN,0^XZ';

  return buffer;
};

export const generateItemLabelSubway = (
  order: OrderType,
  item: OrderDetailType,
  index: number,
  length: number,
) => {
  // init string as buffer
  let buffer = '^XA^CI28^MUD^POI^FWB';

  buffer += subwayItemHead(item);

  buffer += subwayItemTail(order, index, length);

  // close buffer
  buffer += '^FWN,0^XZ';

  return buffer;
};

export const generateLabelSubway = (
  order: OrderType,
  tempItemStore: OrderDetailType[],
) => {
  // Store items here, then write label
  let itemBatchBuffer = '';
  let labelBuffer: string[] = [];
  const receiptHead = '^XA^CI28^MUD^POI^FWB' + subwayHead(order);
  // Max height for a label is 605
  const MAX_HEIGHT = 605;
  let batchHeight = 0;
  let batchOffset = 0;

  // Iterate through items and generate,
  // if batch is full, write label to buffer and reset
  tempItemStore.forEach((item, index) => {
    let { label, height } = subwayContents(item, batchOffset);

    // Check if adding the next item exceeds max height
    if (batchHeight + height > MAX_HEIGHT) {
      labelBuffer.push(receiptHead + itemBatchBuffer);
      itemBatchBuffer = '';
      batchHeight = 0;
      batchOffset = 0;

      // recalculate label to adhere to new offset
      const recalcData = subwayContents(item, batchOffset);
      label = recalcData.label;
      height = recalcData.height;
    }

    // Update batch height and offset
    batchHeight += height;
    batchOffset += height;
    itemBatchBuffer += label;

    // If it's the last item, add the remaining buffer to the labelBuffer
    if (index === tempItemStore.length - 1 && itemBatchBuffer) {
      labelBuffer.push(receiptHead + itemBatchBuffer);
    }
  });

  // Add tail to each label
  labelBuffer = labelBuffer.map((label, index) => {
    const receiptTail =
      subwayTail(order, index + 1, labelBuffer.length) + '^FWN,0^XZ';

    return label + receiptTail;
  });

  return labelBuffer.join('');
};
