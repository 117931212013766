import { AdminRole, FullPermissions } from '@monorepo/upsell/roles';
import {
  ActionMapType,
  PropelAuthStateType,
  AuthUserType,
  PropelUserType,
  PropelActiveOrgIdType,
} from '../types/auth';

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  CHANGE_STORE = 'CHANGE_STORE',
  CHANGE_GLOBAL_BRANCH = 'CHANGE_GLOBAL_BRANCH',
  CHANGE_ROLE = 'CHANGE_ROLE',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    user: AuthUserType;
    propelUser: PropelUserType;
    branchIds: string[];
    globalBranchIds: string[];
    role: AdminRole | null;
    currentStoreId: string | null;
    currentOrgName: string | null;
    permissions: FullPermissions[];
    propelActiveOrgId: PropelActiveOrgIdType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
    propelUser: PropelUserType;
    branchIds: string[];
    globalBranchIds: string[];
    role: AdminRole | null;
    currentStoreId: string | null;
    currentOrgName: string | null;
    permissions: FullPermissions[];
    propelActiveOrgId: PropelActiveOrgIdType;
  };
  [Types.CHANGE_STORE]: {
    currentStoreId: string | null;
    currentOrgName: string | null;
    role: AdminRole | null;
    branchIds: string[];
    globalBranchIds: string[];
    permissions: FullPermissions[];
    propelActiveOrgId: PropelActiveOrgIdType;
  };
  [Types.CHANGE_GLOBAL_BRANCH]: {
    globalBranchIds: string[];
  };
  [Types.CHANGE_ROLE]: {
    role: AdminRole | null;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: PropelAuthStateType = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  propelUser: null,
  branchIds: [],
  globalBranchIds: [],
  role: null,
  currentStoreId: null,
  currentOrgName: null,
  permissions: [],
  propelActiveOrgId: '',
};

const reducer = (
  state: PropelAuthStateType,
  action: ActionsType,
): PropelAuthStateType => {
  switch (action.type) {
    case Types.INITIAL:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        propelUser: action.payload.propelUser,
        user: action.payload.user,
        branchIds: action.payload.branchIds,
        globalBranchIds: action.payload.globalBranchIds,
        role: action.payload.role,
        currentStoreId: action.payload.currentStoreId,
        currentOrgName: action.payload.currentOrgName,
        permissions: action.payload.permissions,
        propelActiveOrgId: action.payload.propelActiveOrgId,
      };
    case Types.LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        role: action.payload.role,
        propelUser: action.payload.propelUser,
        branchIds: action.payload.branchIds,
        globalBranchIds: action.payload.globalBranchIds,
        currentStoreId: action.payload.currentStoreId,
        currentOrgName: action.payload.currentOrgName,
        permissions: action.payload.permissions,
        propelActiveOrgId: action.payload.propelActiveOrgId,
      };
    case Types.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        propelUser: null,
        user: null,
        branchIds: [],
        globalBranchIds: [],
        role: null,
        currentStoreId: null,
        currentOrgName: null,
        permissions: [],
        propelActiveOrgId: '',
      };
    case Types.CHANGE_STORE:
      return {
        ...state,
        currentStoreId: action.payload.currentStoreId,
        currentOrgName: action.payload.currentOrgName,
        role: action.payload.role,
        branchIds: action.payload.branchIds,
        globalBranchIds: action.payload.globalBranchIds,
        permissions: action.payload.permissions,
        propelActiveOrgId: action.payload.propelActiveOrgId,
      };
    case Types.CHANGE_GLOBAL_BRANCH:
      return {
        ...state,
        globalBranchIds: action.payload.globalBranchIds,
      };
    case Types.CHANGE_ROLE:
      return {
        ...state,
        role: action.payload.role,
      };
    default:
      return state;
  }
};

export { initialState, reducer, Types };
