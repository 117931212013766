const convertVariations = {
  toVariables: (variant: any): any[] => {
    if (!variant) {
      return [];
    }
    const result = variant.variants.map((v: any) => ({
      variable: v.attribute.variable,
      attribute: v.attribute._id,
    }));

    return result;
  },
  toVariant: (variables: any[], data: any): any => {
    if (!variables || !data) {
      return null;
    }

    const getVariation = () =>
      data.variations?.find((variation: any) => {
        const variants = variation.variants.map((variant: any) => ({
          variable: variant.variable._id,
          attributes: variant.attribute._id,
        }));

        const matches = variants.filter(
          (variant: any) =>
            variables.find(
              (outputVariant: any) =>
                outputVariant.variable === variant.variable &&
                outputVariant.attribute === variant.attributes,
            ) !== undefined,
        );

        return matches.length === variants.length;
      });

    return getVariation() || null;
  },
};

export default convertVariations;
