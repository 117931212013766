// Normalize two input number with total 100
const normalizeNumber = (num1: number, num2: number) => {
  const total = num1 + num2;

  if (total === 0) {
    return {
      num1: 50,
      num2: 50,
    };
  }

  const normalize = (count: number): number =>
    parseFloat(((count / total) * 100).toFixed(1));

  return {
    num1: normalize(num1),
    num2: normalize(num2),
  };
};

export default normalizeNumber;
