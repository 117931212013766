import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { AdminType } from '../../@types/v2/admins';

// ----------------------------------------------------------------------

type UsersStateType = {
  isLoading: boolean;
  error: boolean;
  sortBy: string | null;
  data: AdminType;
};

const initialState: UsersStateType = {
  isLoading: false,
  error: false,
  sortBy: null,
  data: {} as AdminType,
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // SET USER
    setUser(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
  },
});

export const useUsers = (): UsersStateType =>
  useSelector((RootState: { users: UsersStateType }) => RootState.users);

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, stopLoading, hasError, setUser } = slice.actions;
