import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import axios, { AxiosError, AxiosInstance } from 'axios';
import { useAuthInfo } from '@propelauth/react';
import { usePropelAuthContext } from '../auth/useAuthContext';

// Create a context for the axios instance
export const AxiosContext = createContext<AxiosInstance | null>(null);

type Props = {
  children: React.ReactNode;
  serverUrl?: string;
};

export const AxiosProvider = ({ children, serverUrl }: Props) => {
  const authInfo = useAuthInfo();
  const authInfoRef = useRef(authInfo);
  const { currentStoreId } = usePropelAuthContext();

  useEffect(() => {
    authInfoRef.current = authInfo;
  }, [authInfo]);

  const axiosInstance = useMemo(() => {
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
      ...(currentStoreId ? { 'selected-store': currentStoreId } : {}),
    };

    const accessToken = authInfoRef.current.accessToken;

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
      headers.Source = 'pos';
    }

    const instance = axios.create({
      baseURL: serverUrl,
      headers,
    });

    instance.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        if (error.response) {
          return Promise.reject(error.response.data);
        }
        if (error.request) {
          return Promise.reject('No response received from the server');
        }
        return Promise.reject('Error setting up the request');
      },
    );

    return instance;
  }, [serverUrl, currentStoreId, authInfoRef]);

  return (
    <AxiosContext.Provider value={axiosInstance}>
      {children}
    </AxiosContext.Provider>
  );
};

export const useAxios = () => {
  const axiosInstance = useContext(AxiosContext);
  if (!axiosInstance) {
    throw new Error('useAxios must be used within an AxiosProvider');
  }
  return axiosInstance;
};
