import { AppRouter } from '@monorepo/dashboard-api/router';
import { hc } from 'hono/client';

export function createDashboardClient({
  serverUrl,
  orgName,
  token,
}: {
  serverUrl: string;
  orgName: string;
  token: string;
}) {
  return hc<AppRouter>(serverUrl, {
    headers: {
      'X-Auth-OrgName': orgName,
      Authorization: `Bearer ${token}`,
    },
  });
}

export type DashboardClient = ReturnType<typeof createDashboardClient>;
