import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
// --- DASHBOARD ---
import dashboardReducer from './reducers/dashboard';
// --- REPORT ---
import reportsReducer from './reducers/reports';
// --- PRODUCTS ---
import dealsReducer from './reducers/deals';
import categoriesReducer from './reducers/categories';
import couponReducer from './reducers/coupon';
import discountReducer from './reducers/discount';
import variantReducer from './reducers/variant';
import extraReducer from './reducers/extra';
import productsReducer from './reducers/products';
import tagsReducer from './reducers/tags';
import giftcardsReducer from './reducers/giftcard';
import ingredientReducer from './reducers/ingredient';
// --- INVENTORY MANAGEMENT ---
import inventoryReducer from './reducers/inventory';
// ---- ORDERS -----
import orderListReducer from './reducers/orders/list';
import orderDetailsReducer from './reducers/orders/details';
import orderOverviewReducer from './reducers/orders/overview';
import cartReducer from './reducers/orders/cart';
// ----- CUSTOMERS -----
import customerListReducer from './reducers/customers/list';
import customerDetailsReducer from './reducers/customers/details';
import companyDetailsReducer from './reducers/customers/company-details';
// --- ORDER DISPLAY ---
import kitchenReducer from './reducers/kitchen';
// --- ONLINE STORES ---
import widgetsReducer from './reducers/online-store/widgets';
import kioskReducer from './reducers/online-store/kiosk';
// --- TEAM MANAGEMENT ---
import teamManagementReducer from './reducers/team-management';
// --- PAYMENTS ---
import paymentsReducer from './reducers/payments';
// ---- SETTINGS ----
import settingsReducer from './reducers/settings/settings';
import accountantKeysReducer from './reducers/settings/accountant-keys';
import branchesReducer from './reducers/settings/branches';
import taxesReducer from './reducers/settings/taxes';
// --- TABLE GROUP --
import tableGroupReducer from './reducers/table-group';
// --- MEDIA LIBRARY ---
import mediaLibraryReducer from './reducers/media-library';
// --- INTEGRATIONS ---
import integrationsReducer from './reducers/integration';
// --- USER -----
import usersReducer from './reducers/users';
// --- OTHERS ---
// @TODO: Modals / dialog shouldn't exist on redux reducer
import productDialogReducer from './reducers/orders/dialog/product_dialog';
import pizzaOrderDialogReducer from './reducers/orders/dialog/pizza_dialog';
import successModalReducer from './reducers/orders/dialog/success_modal';
import dealOrderDialogReducer from './reducers/orders/dialog/deal_dialog';

// ----------------------------------------------------------------------

export const createNoopStorage = () => ({
  getItem(_key: string) {
    return Promise.resolve(null);
  },
  setItem(_key: string, value: any) {
    return Promise.resolve(value);
  },
  removeItem(_key: string) {
    return Promise.resolve();
  },
});

export const storage =
  typeof window !== 'undefined'
    ? createWebStorage('local')
    : createNoopStorage();

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const cartPersistConfig = {
  key: 'cart',
  storage,
  whitelist: ['items', 'store_id'],
};

const customerPersistConfig = {
  key: 'customers',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const dealsPersistConfig = {
  key: 'deals',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['currentDeal'],
};

const tagsPersistConfig = {
  key: 'tags',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['currentTag'],
};

const ordersPersistConfig = {
  key: 'orders',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['defaultCalendar', 'filterCancelled'],
};

const branchesPersistConfig = {
  key: 'branches',
  storage,
  keyPrefix: 'redux-',
  whitelist: [''],
};

const orderOverviewPersistConfig = {
  key: 'order-overview',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['defaultCalendar'],
};

const giftcardsPersistConfig = {
  key: 'giftcards',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const dashboardPersistConfig = {
  key: 'dashboard',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const mediaLibraryConfig = {
  key: 'media-library',
  storage,
  keyPrefix: 'redux-',
  whitelist: [''],
};

const rootReducer = combineReducers({
  // --- DASHBOARD ---
  dashboard: persistReducer(dashboardPersistConfig, dashboardReducer),
  // --- REPORT ---
  reports: reportsReducer,
  // --- PRODUCTS ---
  products: productsReducer,
  deals: persistReducer(dealsPersistConfig, dealsReducer),
  categories: categoriesReducer,
  variants: variantReducer,
  extras: extraReducer,
  tags: persistReducer(tagsPersistConfig, tagsReducer),
  giftcards: persistReducer(giftcardsPersistConfig, giftcardsReducer),
  coupon: couponReducer,
  discount: discountReducer,
  ingredient: ingredientReducer,
  // --- INVENTORY MANAGEMENT ---
  inventory: inventoryReducer,
  // --- ORDERS ---
  orderList: persistReducer(ordersPersistConfig, orderListReducer),
  orderDetails: orderDetailsReducer,
  orderOverview: persistReducer(
    orderOverviewPersistConfig,
    orderOverviewReducer,
  ),
  cart: persistReducer(cartPersistConfig, cartReducer),
  // --- CUSTOMERS ---
  customerList: persistReducer(customerPersistConfig, customerListReducer),
  customerDetails: customerDetailsReducer,
  companyDetails: companyDetailsReducer,
  // --- ORDER DISPLAY ---
  kitchen: kitchenReducer,
  // --- ONLINE STORES ---
  widgets: widgetsReducer,
  kiosk: kioskReducer,
  // --- SETTINGS ---
  settings: settingsReducer,
  branches: persistReducer(branchesPersistConfig, branchesReducer),
  accountantKeys: accountantKeysReducer,
  taxes: taxesReducer,
  // --- TEAM MANAGEMENT ---
  teamManagement: teamManagementReducer,
  // --- PAYMENTS ---
  payments: paymentsReducer,
  // --- TABLE GROUPS ---
  tableGroups: tableGroupReducer,
  // --- MEDIA LIBRARY ---
  mediaLibrary: persistReducer(mediaLibraryConfig, mediaLibraryReducer),
  // --- INTEGRATIONS ---
  integrations: integrationsReducer,
  // --- USER -----
  users: usersReducer,
  // --- OTHERS ---
  // @TODO: Modals / dialog shouldn't exist on redux reducer to simplify things
  // it will become complex to handle it in the future
  dealOrderDialog: dealOrderDialogReducer,
  productDialog: productDialogReducer,
  successModal: successModalReducer,
  pizzaOrderDialog: pizzaOrderDialogReducer,
});

export default rootReducer;
