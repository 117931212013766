import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import {
  HistoryType,
  InventoryCategoryType,
  InventoryDataType,
} from '../../@types/v2/inventory';

// ----------------------------------------------------------------------

type InventoryStateType = {
  isLoading: boolean;
  error: boolean;
  data: InventoryDataType;
  setting: {
    categories: InventoryCategoryType[];
  };
  history: HistoryType[];
};

const initialState: InventoryStateType = {
  isLoading: false,
  error: false,
  data: {
    productMultiStore: {
      list: [],
      sortBy: 'name-desc',
    },
    product: {
      list: [],
      sortBy: 'name-desc',
    },
    variation: {
      list: [],
      sortBy: 'name-desc',
    },
    transfer: {
      list: [],
      sortBy: 'name-desc',
      pagination: {
        total: 0,
        page: 1,
        rowPerPage: 25,
      },
    },
  },
  setting: {
    categories: [],
  },
  history: [],
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // SET PRODUCT Inventory
    setProductInventoryData(state, action) {
      state.isLoading = false;
      state.data.product.list = action.payload;
    },

    // SET PRODUCT Inventory
    setProductInventoryMultiStoreData(state, action) {
      state.isLoading = false;
      state.data.productMultiStore.list = action.payload;
    },

    // SET VARIATION
    setVariationInventoryData(state, action) {
      state.isLoading = false;
      state.data.variation.list = action.payload;
    },

    // SET TRANSFER
    setTransferInventoryData(state, action) {
      const { data, pagination } = action.payload;
      state.data.transfer.list = data;
      state.data.transfer.pagination.total = pagination.total;
      state.data.transfer.pagination.page = pagination.page;
      state.isLoading = false;
    },

    setHistory(state, action) {
      state.history = action.payload;
    },

    // SET CATEGORIES
    setSettingCategories(state, action) {
      state.isLoading = false;
      state.setting.categories = action.payload;
    },

    // SORT INVENTORY
    sortByInventory(
      state,
      action: {
        payload: {
          type: 'product' | 'variation';
          sortBy: string;
        };
      },
    ) {
      const { type, sortBy } = action.payload;
      switch (type) {
        case 'product':
          state.data.product.sortBy = sortBy;
          break;
        case 'variation':
          state.data.variation.sortBy = sortBy;
          break;
        default:
          break;
      }
    },
  },
});

export const useInventory = (): InventoryStateType =>
  useSelector(
    (RootState: { inventory: InventoryStateType }) => RootState.inventory,
  );

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  stopLoading,
  sortByInventory,
  hasError,
  setProductInventoryData,
  setSettingCategories,
  setVariationInventoryData,
  setHistory,
  setTransferInventoryData,
  setProductInventoryMultiStoreData,
} = slice.actions;
