import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from '../../store';
import {
  KioskDeviceType,
  KioskType,
} from '../../../@types/v2/online-store/kiosk';

// ----------------------------------------------------------------------

type StylingType = 'logo' | 'color' | 'font' | 'shape' | 'button' | 'badges';
type LayoutType = 'home' | 'splash' | 'orderMethod' | 'detailProduct' | 'cart';
type NestedLayoutType =
  | 'category'
  | 'category:order'
  | 'product'
  | 'product:order'
  | 'splash-inactive'
  | 'splash-active'
  | 'splash-active:action'
  | 'method-choice'
  | 'orderMethod-styles'
  | 'detailProduct-variation_extras'
  | 'detailProduct-variation'
  | 'detailProduct-simple'
  | 'detailProduct-deals'
  | 'detailProduct-variation_extras:styles';

export type FormType = 'initial' | StylingType | LayoutType | NestedLayoutType;

type KioskStateType = {
  loading: {
    main: boolean;
    activation: boolean;
    device_detail: boolean;
  };
  error: Record<string, any> | null;
  kiosk: KioskType | null;
  kioskDevices: KioskDeviceType[] | [];
  selectedKioskDevice: KioskDeviceType | null;
  form: FormType;
  hideFeature: {
    styling: {
      badges: boolean;
      shapes: boolean;
      button: {
        shapes: boolean;
        text: boolean;
      };
    };
    pages: {
      home: {
        category: boolean;
        product: boolean;
      };
      order_method: {
        text_style: boolean;
      };
      detail_product: {
        variation_extras: boolean;
        variation: boolean;
        deals: boolean;
      };
      cart: {
        logo: boolean;
        button: boolean;
        text_style: boolean;
      };
    };
  };
};

const initialState: KioskStateType = {
  loading: {
    main: false,
    activation: false,
    device_detail: false,
  },
  error: null,
  kiosk: null,
  kioskDevices: [],
  selectedKioskDevice: null,
  form: 'initial',
  hideFeature: {
    styling: {
      badges: true,
      shapes: false,
      button: {
        shapes: false,
        text: true,
      },
    },
    pages: {
      home: {
        category: false,
        product: false,
      },
      order_method: {
        text_style: true,
      },
      detail_product: {
        variation_extras: false,
        variation: false,
        deals: false,
      },
      cart: {
        button: false,
        logo: false,
        text_style: true,
      },
    },
  },
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'kiosk',
  initialState,
  reducers: {
    startLoading(state, action) {
      switch (action.payload) {
        case 'main':
          state.loading.main = true;
          break;
        case 'activation':
          state.loading.activation = true;
          break;
        case 'device_detail':
          state.loading.device_detail = true;
          break;
        default:
          break;
      }
    },
    stopLoading(state, action) {
      switch (action.payload) {
        case 'main':
          state.loading.main = false;
          break;
        case 'activation':
          state.loading.activation = false;
          break;
        case 'device_detail':
          state.loading.device_detail = false;
          break;
        default:
          break;
      }
    },
    hasError(state, action) {
      state.loading.main = false;
      state.error = action.payload;
    },
    setKiosk(state, action) {
      state.loading.main = false;
      state.loading.activation = false;
      state.loading.device_detail = false;
      state.kiosk = action.payload;
    },
    setKioskDevices(state, action) {
      state.loading.main = false;
      state.loading.activation = false;
      state.loading.device_detail = false;
      state.kioskDevices = action.payload;
    },
    setSelectedKiosk(state, action) {
      state.loading.main = false;
      state.loading.activation = false;
      state.loading.device_detail = false;
      state.selectedKioskDevice = action.payload;
    },
    setFormType(state, action) {
      state.loading.main = false;
      state.form = action.payload;
    },
  },
});

export const useKiosk = (): KioskStateType =>
  useSelector((RootState: { kiosk: KioskStateType }) => RootState.kiosk);

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  stopLoading,
  hasError,
  setKiosk,
  setFormType,
  setSelectedKiosk,
  setKioskDevices,
} = slice.actions;
