import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { PaymentType } from '../../@types/v2/payments';

// ----------------------------------------------------------------------

type PaymentStateType = {
  error: boolean;
  isLoading: boolean;
  data: PaymentType[];
};

const initialState: PaymentStateType = {
  error: false,
  isLoading: false,
  data: [],
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setPayments(state, action) {
      state.isLoading = false;
      state.data = action.payload.sort(
        (a: Partial<PaymentType>, b: Partial<PaymentType>) =>
          (a.payment_method_title || '').localeCompare(
            b.payment_method_title || '',
          ),
      );
    },
  },
});

export const usePayments = (): PaymentStateType =>
  useSelector(
    (RootState: { payments: PaymentStateType }) => RootState.payments,
  );

// Actions
export const { startLoading, stopLoading, hasError, setPayments } =
  slice.actions;

// Reducer
export default slice.reducer;
