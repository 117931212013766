import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { CustomerType } from '../../../@types/v2/customers/list';
import { ExpenseSummaryType } from '../../../@types/v2/customers/details';
import { OrderType } from '../../../@types/v2/orders/list';

// ----------------------------------------------------------------------

type CustomerDetailsStateType = {
  error: boolean;
  customer: {
    data: CustomerType;
    isLoading: boolean;
  };
  orderHistory: {
    data: OrderType[];
    pagination: {
      page: number;
      limit: number;
      total: number;
      hasMoreResults: boolean;
    };
    isLoading: boolean;
    mergeLoading: boolean;
  };
  expenseSummary: {
    data: ExpenseSummaryType;
    isLoading: boolean;
  };
};

const initialState: CustomerDetailsStateType = {
  error: false,
  customer: {
    data: {} as CustomerType,
    isLoading: true,
  },
  orderHistory: {
    data: [],
    pagination: {
      page: 1,
      limit: 25,
      total: 0,
      hasMoreResults: true,
    },
    isLoading: true,
    mergeLoading: true,
  },
  expenseSummary: {
    data: {} as ExpenseSummaryType,
    isLoading: true,
  },
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'customer-details',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state, action) {
      switch (action.payload) {
        case 'customer':
          state.customer.isLoading = true;
          break;
        case 'expense-summary':
          state.expenseSummary.isLoading = true;
          break;
        case 'order':
          state.orderHistory.isLoading = true;
          break;
        case 'order-merge':
          state.orderHistory.mergeLoading = true;
          break;
        default:
          break;
      }
    },

    // STOP LOADING
    stopLoading(state, action) {
      switch (action.payload) {
        case 'customer':
          state.customer.isLoading = false;
          break;
        case 'expense-summary':
          state.expenseSummary.isLoading = false;
          break;
        case 'order':
          state.orderHistory.isLoading = false;
          break;
        case 'order-merge':
          state.orderHistory.mergeLoading = false;
          break;
        default:
          break;
      }
    },

    // SET ORDER DETAIL DATA
    setCustomer: (state, action) => {
      state.customer.data = action.payload;
      state.customer.isLoading = false;
    },

    // SET ORDER HISTORY
    setOrderHistory(state, action) {
      state.orderHistory.data = action.payload;
      state.orderHistory.mergeLoading = false;
      state.orderHistory.isLoading = false;
    },

    setOrderHistoryPagination(state, action) {
      state.orderHistory.pagination = action.payload;
    },

    // SET EXPENSE SUMMARY
    setExpenseSummary(state, action) {
      state.expenseSummary.data = action.payload;
      state.expenseSummary.isLoading = false;
    },
  },
});

export const useCustomerDetails = (): CustomerDetailsStateType =>
  useSelector(
    (RootState: { customerDetails: CustomerDetailsStateType }) =>
      RootState.customerDetails,
  );

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  stopLoading,
  setCustomer,
  setOrderHistory,
  setOrderHistoryPagination,
  setExpenseSummary,
} = slice.actions;
