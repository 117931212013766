import { createSlice } from '@reduxjs/toolkit';
import type { IngredientType } from '../../@types/v2/ingredient';
import { useSelector } from '../store';

// ----------------------------------------------------------------------

type IngredientStateType = {
  isLoading: boolean;
  error: boolean;
  sortBy: string | null;
  data: IngredientType[];
  ingredient: IngredientType | null;
};

const initialState: IngredientStateType = {
  isLoading: false,
  error: false,
  sortBy: null,
  data: [] as IngredientType[],
  ingredient: null,
};

// ----------------------------------------------------------------------

const ingredientSlice = createSlice({
  name: 'ingredient',
  initialState,
  reducers: {
    // Start loading
    startLoading(state) {
      state.isLoading = true;
    },
    // Has error
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // Set Ingredients
    setIngredients(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    setIngredient(state, action) {
      state.isLoading = false;
      state.ingredient = action.payload;
    },
    // Sort Ingredient
    sortByIngredient(state, action) {
      state.sortBy = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const useIngredient = (): IngredientStateType =>
  useSelector((RootState) => RootState.ingredient);

// Reducer
export default ingredientSlice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  setIngredient,
  setIngredients,
  sortByIngredient,
  reset,
} = ingredientSlice.actions;
