import { format } from 'date-fns';
import { addMinutesToDate } from '../date-time';
import type {
  OrderDetailType,
  OrderType,
  ExtrasType,
} from '../../@types/v2/orders/list';
import {
  Left as SplitPizzaLeft,
  Right as SplitPizzaRight,
} from '../../assets/svg/SplitPizzaSvg';
import { Delivery, Paid, Pickup, DineIn } from '../../assets/svg/ReceiptSvg';
import { BlackWithText } from '../../assets/svg/UpsellLogoSvg';

type HeaderProps = {
  customer: OrderType['customer'];
  store: OrderType['store'];
  method: OrderType['order_method'];
  number: OrderType['order_number'];
  branch: OrderType['branch'];
  createdAt: OrderType['createdAt'];
  paid: boolean;
  mode: 'order' | 'receipt';
  waitingTime: number;
  notes: OrderType['order_notes'];
  ssn?: string;
  table: OrderType['table'];
};

type CustomerBlockProps = Pick<
  HeaderProps,
  | 'customer'
  | 'mode'
  | 'notes'
  | 'ssn'
  | 'table'
  | 'method'
  | 'branch'
  | 'createdAt'
  | 'waitingTime'
  | 'paid'
>;

type DeliveryProps = {
  shipping: OrderType['shipping'];
};

type NoteProps = {
  notes: OrderType['order_notes'];
};

type BodyProps = {
  currency: OrderType['currency'];
  items: OrderType['items'];
  additionalCosts: OrderType['additional_cost'];
  total: OrderType['total'];
  sub_total: OrderType['sub_total'];
  discount_total: OrderType['discount_total'];
  tax_total: OrderType['tax_total'];
  paid: boolean;
  mode: 'order' | 'receipt';
  method: OrderType['order_method'];
  tax_rates: OrderType['tax_rates'];
};

type ItemProps = {
  item: OrderDetailType;
  paid: boolean;
  mode?: 'order' | 'receipt';
};

type MethodBarProps = {
  method: OrderType['order_method'];
};

type RenderMethodIconProps = MethodBarProps & {
  isBar?: boolean;
};

export const PRIORITIZED_EXTRAS = [
  'Grænmeti & ávextir',
  'Krydd',
  'Ostur',
  'Kjötálegg',
  'Sósur',
];

export const DineInTableBlock = ({ table }: { table: OrderType['table'] }) => {
  // Fallback for tables created in QR Ordering
  const [tableName, tableGroupName] = table.table_group_name
    ? [table.name, table.table_group_name]
    : [table.name.split(',')[1], table.name.split(',')[0]];

  return (
    <>
      <div
        style={{
          borderBottom: '1px dashed #D4D4D4',
          padding: '1px',
          width: '100%',
        }}
      />
      <div
        style={{
          padding: '6px 0',
          width: '100%',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div
          style={{ fontWeight: 700, fontSize: '28px' }}
        >{`${tableName}`}</div>
        <div
          style={{ fontWeight: 700, fontSize: '12px', paddingTop: '6px' }}
        >{`${tableGroupName}`}</div>
      </div>
    </>
  );
};

const CustomerBlock = ({
  customer,
  mode,
  notes,
  ssn,
  table,
  method,
  branch,
  createdAt,
  waitingTime,
  paid,
}: CustomerBlockProps) => {
  // Fallback for tables created in QR Ordering
  let tableString = '';

  if (table) {
    const [tableName, tableGroupName] = table.table_group_name
      ? [table.name, table.table_group_name]
      : [table.name.split(',')[1], table.name.split(',')[0]];

    tableString = `${tableGroupName}, ${tableName}`;
  }

  const estimationWaitingTime =
    method === 'delivery'
      ? branch.settings.delivery.waiting_time || 0
      : branch.settings.pickup.waiting_time || 0;

  const estimatedTime = addMinutesToDate(
    new Date(createdAt),
    waitingTime ?? estimationWaitingTime,
  );

  const waitingTimeInterval = waitingTime >= 60 ? 10 : 5;

  let methodText;

  switch (method) {
    case 'delivery':
      methodText = 'Delivery at';
      break;
    case 'pickup':
      methodText = 'Pick up at';
      break;
    default:
      methodText = 'Serve at';
      break;
  }

  if (mode === 'receipt') {
    return (
      <>
        <div
          style={{
            borderBottom: '1px dashed #D4D4D4',
            padding: '1px',
            width: '100%',
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: '10px',
            width: '100%',
            padding: '6px 0',
          }}
        >
          <div>{`Customer: ${customer?.name || 'GUEST'}`}</div>
        </div>
        {customer?.ssn || ssn ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '10px',
              width: '100%',
              padding: '6px 0',
            }}
          >
            {`SSN: ${customer?.ssn || ssn}`}
          </div>
        ) : (
          <></>
        )}

        {table ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '10px',
              width: '100%',
              paddingBottom: '6px',
            }}
          >
            {`Table: ${tableString}`}
          </div>
        ) : (
          <></>
        )}

        {notes.map((note, index) =>
          note.note ? (
            <div
              style={{
                fontSize: '10px',
                width: '100%',
                paddingBottom: '6px',
              }}
              key={index}
            >
              {` ${note.note}`}
            </div>
          ) : (
            <></>
          ),
        )}
      </>
    );
  }

  if (method === 'dine-in') {
    return <></>;
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '10px',
          width: '100%',
          paddingBottom: '6px',
        }}
      >
        <div
          style={{
            fontWeight: 400,
          }}
        >
          Date & Time
        </div>
        <div
          style={{
            fontWeight: 500,
          }}
        >
          {format(new Date(createdAt), 'dd-MM-yy, HH:mm')}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '10px',
          width: '100%',
          paddingBottom: '6px',
        }}
      >
        <div
          style={{
            fontWeight: 400,
          }}
        >
          Customer
        </div>
        <div
          style={{
            fontWeight: 500,
          }}
        >
          {customer?.name || 'GUEST'}
        </div>
      </div>

      {customer?.phone && customer?.phone.phone_number ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: '10px',
            width: '100%',
            paddingBottom: '6px',
          }}
        >
          <div
            style={{
              fontWeight: 400,
            }}
          >
            Phone
          </div>
          <div
            style={{
              fontWeight: 500,
            }}
          >
            {customer?.phone.phone_number}
          </div>
        </div>
      ) : (
        <></>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '10px',
          width: '100%',
          paddingBottom: '6px',
        }}
      >
        <div
          style={{
            fontWeight: 400,
          }}
        >
          Method
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: '10px',
          }}
        >
          <RenderMethodIcon method={method} />
          <div
            style={{
              paddingLeft: '6px',
              fontWeight: 500,
            }}
          >
            {method}
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '10px',
          width: '100%',
          paddingBottom: `${paid ? '6px' : 0}`,
        }}
      >
        <div
          style={{
            fontWeight: 400,
          }}
        >
          {methodText}
        </div>
        <div
          style={{
            fontWeight: 500,
          }}
        >
          {/* estimated time - estimated time + 5 minutes */}
          {`${format(estimatedTime, 'HH:mm')} - ${format(
            addMinutesToDate(estimatedTime, waitingTimeInterval),
            'HH:mm',
          )}`}
        </div>
      </div>

      {paid && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: '10px',
            width: '100%',
          }}
        >
          <div>Status</div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '10px',
            }}
          >
            <Paid />
            <div
              style={{
                paddingLeft: '6px',
              }}
            >
              Paid
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const Header = ({
  customer,
  method,
  number,
  branch,
  store,
  paid,
  mode,
  createdAt,
  waitingTime,
  ssn,
  notes,
  table,
}: HeaderProps) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      padding: '7px 7px',
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: '10px',
        paddingBottom: '6px',
      }}
    >
      {store?.receipt_logo && store?.receipt_logo.url ? (
        <img
          src={store?.receipt_logo.url}
          alt={store?.receipt_logo.alt || 'logo'}
          style={{
            maxWidth: '50%',
            maxHeight: '200px',
            paddingBottom: '6px',
          }}
        />
      ) : (
        <BlackWithText />
      )}
      <div
        style={{
          paddingTop: '6px',
          fontWeight: 700,
        }}
      >
        {branch?.business_name}
      </div>
      {store?.business_ssn && <div>{`Kennitala: ${store.business_ssn}`}</div>}
      <div>{`${branch?.address?.address}, ${branch?.address?.city} ${branch?.address?.postcode}`}</div>
      {store?.vat_number && <div>{`VAT number: ${store.vat_number}`}</div>}
    </div>
    {mode === 'order' && method === 'dine-in' && table && (
      <DineInTableBlock table={table} />
    )}
    {mode === 'receipt' ? (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: '10px',
            width: '100%',
            padding: '6px 0',
          }}
        >
          <div>{/* Waiter */}</div>
          <div>{`${format(new Date(createdAt), 'dd-MM-yy, HH:mm')}`}</div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: '10px',
            width: '100%',
            paddingBottom: '6px',
          }}
        >
          <div>{`Order Type: ${method}`}</div>
          <div>{`Inv: #${number}`}</div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: '12px',
            width: '100%',
            paddingBottom: '6px',
          }}
        >
          <div style={{ fontWeight: 700 }}>{`Order #${number}`}</div>
          <div style={{ fontWeight: 700 }}>{`${method}`}</div>
        </div>
      </div>
    ) : (
      <>
        <div
          style={{
            borderBottom: '1px dashed #D4D4D4',
            padding: '1px',
            width: '100%',
          }}
        />
        <div style={{ fontWeight: 700, fontSize: '12px', padding: '6px 0' }}>
          {`Order #${number}`}
        </div>
      </>
    )}
    <CustomerBlock
      customer={customer}
      method={method}
      branch={branch}
      paid={paid}
      mode={mode}
      createdAt={createdAt}
      waitingTime={waitingTime}
      ssn={ssn}
      notes={notes}
      table={table}
    />
  </div>
);

const RenderMethodIcon = ({ method, isBar }: RenderMethodIconProps) => {
  switch (method) {
    case 'pickup':
      return <Pickup fill={isBar ? 'white' : '#333333'} />;
    case 'dine-in':
      return <DineIn />;
    default:
      return <Delivery />;
  }
};

export const PaymentIndexBar = (index: number) => (
  <div
    style={{
      padding: '4px',
      color: 'white',
      backgroundColor: 'black',
      width: '100%',
      textAlign: 'center',
    }}
  >
    {`PAYMENT ${index + 1}`}
  </div>
);

export const TestBar = () => (
  <div
    style={{
      padding: '4px',
      color: 'white',
      backgroundColor: 'black',
      width: '100%',
      textAlign: 'center',
    }}
  >
    TEST
  </div>
);

export const MethodBar = ({ method }: MethodBarProps) => (
  <div
    style={{
      padding: '4px',
      color: method === 'pickup' ? 'white' : 'black',
      backgroundColor: method === 'pickup' ? 'black' : 'white',
      width: '100%',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <RenderMethodIcon method={method} isBar />
    <div
      style={{
        paddingLeft: '6px',
        fontWeight: 500,
        fontSize: '12px',
      }}
    >
      {method}
    </div>
  </div>
);

export const UnpaidBar = () => (
  <div
    style={{
      padding: '4px',
      color: 'white',
      backgroundColor: 'black',
      width: '100%',
      textAlign: 'center',
    }}
  >
    Unpaid
  </div>
);

export const SecondaryOrderNumberBar = ({
  number,
  mode,
}: {
  number: OrderType['secondary_order_number'];
  mode: 'order' | 'receipt';
}) => (
  <div
    style={{
      marginTop: '8px',
      padding: '4px 7px',
      color: 'white',
      backgroundColor: 'black',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <div
      style={{
        fontSize: '12px',
        fontWeight: 700,
      }}
    >
      {mode === 'order' ? 'Number' : 'Your Number'}
    </div>
    <div
      style={{
        fontSize: mode === 'order' ? '24px' : '12px',
        fontWeight: 700,
      }}
    >{`${number}`}</div>
  </div>
);

export const DeliveryAddress = ({ shipping }: DeliveryProps) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      width: '100%',
      padding: '0 7px 6px',
    }}
  >
    <div
      style={{
        borderBottom: '1px dashed #D4D4D4',
        padding: '1px',
        width: '100%',
      }}
    />
    <div
      style={{
        paddingTop: '4px',
        fontWeight: 700,
        fontSize: '12px',
        paddingBottom: '2px',
      }}
    >
      Delivery To
    </div>
    <div
      style={{
        fontSize: '10px',
        width: '100%',
      }}
    >
      {`${shipping?.address}, ${shipping?.postcode}`}
    </div>
    <div
      style={{
        fontSize: '10px',
        width: '100%',
      }}
    >
      {`${shipping.address_detail}`}
    </div>
    {shipping.shipping_note && (
      <div
        style={{
          fontSize: '10px',
          width: '100%',
        }}
      >
        {`NOTE: ${shipping.shipping_note}`}
      </div>
    )}
  </div>
);

export const Note = ({ notes }: NoteProps) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      width: '100%',
      padding: '0 7px',
    }}
  >
    <div
      style={{
        borderBottom: '1px dashed #D4D4D4',
        padding: '1px',
        width: '100%',
      }}
    />
    {notes.map((note, index) => (
      <div
        style={{
          fontSize: '10px',
          width: '100%',
          paddingTop: '4px',
        }}
        key={index}
      >
        {note.note}
      </div>
    ))}
  </div>
);

const SectionDivider = ({ name, index }: { name: string; index: number }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: '6px',
    }}
  >
    {index > 0 ? (
      <div
        style={{
          borderTop: '1px dashed #D4D4D4',
          padding: '1px',
          width: '100%',
        }}
      />
    ) : (
      <></>
    )}
    <div
      style={{
        paddingTop: '4px',
        backgroundColor: 'white',
        fontWeight: 400,
        fontSize: '12px',
      }}
    >
      {name}
    </div>
  </div>
);

// handle items
const Extras = ({ extrasItems }: { extrasItems: ExtrasType['items'] }) => (
  <div>
    {extrasItems.map((item, index) => {
      if (!item.include || item.doubled) {
        return (
          <div
            key={index}
            style={{
              fontWeight: 400,
            }}
          >
            + {item.name} {item.doubled && '(x2)'}
          </div>
        );
      }
      return null;
    })}
  </div>
);

// handle excluded_tiems
const ExtrasInverted = ({
  extrasItems,
}: {
  extrasItems: ExtrasType['excluded_items'];
}) => (
  <div>
    {extrasItems.map(
      (item, index) =>
        item.include && (
          <div
            key={index}
            style={{
              fontWeight: 400,
            }}
          >
            — {item.name} {item.doubled && '(x2)'}
          </div>
        ),
    )}
  </div>
);

const DisplayExtras = ({ extras }: { extras: ExtrasType[] }) => {
  // sort extras based on PRIORITIZED_EXTRAS
  const sortedExtras = extras
    ?.slice()
    .sort(
      (a, b) =>
        PRIORITIZED_EXTRAS.indexOf(a.name) - PRIORITIZED_EXTRAS.indexOf(b.name),
    );

  return (
    <>
      {extras &&
        sortedExtras.map((extra, index) => {
          if (extra.excluded_items) {
            return (
              <ExtrasInverted extrasItems={extra.excluded_items} key={index} />
            );
          }
          return null;
        })}
      {extras &&
        sortedExtras.map((extra, index) => (
          <Extras extrasItems={extra.items} key={index} />
        ))}
    </>
  );
};

const SplitPizza = ({ item, paid, mode }: ItemProps) => {
  const cancelled = item.status === 'cancelled';

  let nameString = '1 x Split Pizza';
  let priceString = paid ? '' : `${item.total} kr`;

  if (cancelled) {
    nameString += ' (Cancelled)';
  }

  if (cancelled) {
    priceString = '0 kr';
  }

  return (
    <div
      style={{
        paddingBottom: '6px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '12px',
          width: '100%',
          fontWeight: 700,
          paddingBottom: '2px',
        }}
      >
        <div>{nameString}</div>
        <div>{priceString}</div>
      </div>

      <div
        style={{
          paddingBottom: '4px',
          fontWeight: 400,
        }}
      >
        {item.split_items[0].variation.name}
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: `${mode === 'order' ? 'column' : 'row'}`,
        }}
      >
        {
          // TO-DO: ADD THESE INTO TYPE SYSTEM

          item.split_items.map((curItem: any, index: number) => (
            <div
              style={{
                display: 'flex',

                width: `${mode === 'order' ? '100%' : '50%'}`,
                columnGap: '4px',
                paddingBottom: `${index === 0 ? '4px' : 0}`,
              }}
              key={index}
            >
              {index === 0 ? <SplitPizzaLeft /> : <SplitPizzaRight />}
              <div
                style={{
                  paddingLeft: '4px',
                }}
              >
                <div
                  style={{
                    fontSize: '12px',
                  }}
                >
                  {curItem.name}
                </div>
                <DisplayExtras extras={curItem.extras} />
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

const Variation = ({ item, paid, mode }: ItemProps) => {
  const sku = item.sku || item.variation?.sku || '';
  const cancelled = item.status === 'cancelled';

  let nameString =
    mode === 'order'
      ? `${sku.length > 0 ? sku + ' ' : ''}${item.name}`
      : item.name;
  let priceString = paid ? '' : `${item.total} kr`;

  if (cancelled) {
    nameString += ' (Cancelled)';
  }

  if (cancelled) {
    priceString = '0 kr';
  }

  return (
    <div
      style={{
        paddingBottom: '6px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '12px',
          width: '100%',
          fontWeight: 700,
          paddingBottom: '2px',
        }}
      >
        <div>{`${item.quantity} x ${nameString}`}</div>
        <div>{priceString}</div>
      </div>
      <div
        style={{
          paddingBottom: '4px',
          fontWeight: 400,
        }}
      >
        {item.variation.name}
      </div>
    </div>
  );
};

const VariationExtras = ({ item, paid, mode }: ItemProps) => {
  const sku = item.sku || item.variation?.sku || '';
  const cancelled = item.status === 'cancelled';

  let nameString =
    mode === 'order'
      ? `${sku.length > 0 ? sku + ' ' : ''}${item.name}`
      : item.name;
  let priceString = paid ? '' : `${item.total} kr`;

  if (cancelled) {
    nameString += ' (Cancelled)';
  }

  if (cancelled) {
    priceString = '0 kr';
  }

  return (
    <div
      style={{
        paddingBottom: '6px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '12px',
          width: '100%',
          fontWeight: 700,
          paddingBottom: '2px',
        }}
      >
        <div>{`${item.quantity} x ${nameString}`}</div>
        <div>{priceString}</div>
      </div>
      <div
        style={{
          paddingBottom: '4px',
          fontWeight: 400,
        }}
      >
        {item.variation.name}
      </div>
      <DisplayExtras extras={item.extras} />
    </div>
  );
};

const SimpleItem = ({ item, paid, mode }: ItemProps) => {
  const sku = item.sku || item.variation?.sku || '';
  const cancelled = item.status === 'cancelled';

  let nameString =
    mode === 'order'
      ? `${sku.length > 0 ? sku + ' ' : ''}${item.name}`
      : item.name;
  let priceString = paid ? '' : `${item.total} kr`;

  if (cancelled) {
    nameString += ' (Cancelled)';
  }

  if (cancelled) {
    priceString = '0 kr';
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '12px',
        width: '100%',
        fontWeight: 700,
        paddingBottom: '6px',
      }}
    >
      <div>{`${item.quantity} x ${nameString}`}</div>
      <div>{paid ? '' : `${priceString} kr`}</div>
    </div>
  );
};

// used for 'custom_deals'
const CustomDeals = ({ item, mode = 'order', paid }: ItemProps) => {
  const sku = item.sku || item.variation?.sku || '';
  const cancelled = item.status === 'cancelled';

  let nameString =
    mode === 'order'
      ? `${sku.length > 0 ? sku + ' ' : ''}${item.name}`
      : item.name;
  let priceString = paid ? '' : `${item.total} kr`;

  if (cancelled) {
    nameString += ' (Cancelled)';
  }

  if (cancelled) {
    priceString = '0 kr';
  }

  if (mode === 'order') {
    // if you want to handle duplicates like in the main function,
    // do it here
  }

  return (
    <div
      style={{
        paddingBottom: '6px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '12px',
          width: '100%',
          fontWeight: 700,
          paddingBottom: '6px',
        }}
      >
        <div>{`${item.quantity} x ${nameString}`}</div>
        <div>{priceString}</div>
      </div>
      <div
      // style={{
      //   marginLeft: '8px',
      // }}
      >
        {item.menus.length > 0 &&
          item.menus.map((curItem, index) => (
            <div
              key={index}
              style={{
                paddingLeft: '12px',
              }}
            >
              {curItem.menu_items.map((menuItem, menuIndex) =>
                renderItem(menuItem, true, mode, menuIndex),
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

// main rendering function, can be used recursively
const renderItem = (
  item: OrderDetailType,
  paid: boolean,
  mode: 'order' | 'receipt',
  index: number,
) => {
  switch (item.type) {
    case 'split_product':
      return <SplitPizza item={item} paid={paid} mode={mode} key={index} />;
    case 'variation_extras':
      return <VariationExtras item={item} paid={paid} key={index} />;
    case 'variation':
      return <Variation item={item} paid={paid} key={index} />;
    case 'custom_deals':
      return <CustomDeals item={item} paid={paid} key={index} mode={mode} />;
    default:
      return <SimpleItem item={item} key={index} paid={paid} />;
  }
};

type Category = {
  name: string;
  position: number;
};

export const Body = ({
  additionalCosts,
  currency,
  items,
  total,
  paid,
  mode,
  sub_total,
  discount_total,
  tax_total,
  method,
  tax_rates,
}: BodyProps) => {
  const categoriesMap: Record<string, Category> = {};

  items.forEach((item) => {
    if (
      item.product_categories &&
      item.product_categories.length > 0 &&
      item.product_categories.some((cat) => cat.primary)
    ) {
      item.product_categories.forEach((category) => {
        if (category.primary && category.category) {
          categoriesMap[category.category.name] = {
            name: category.category.name,
            position: category.category.position ?? 99,
          };
        }
      });
    } else if (item.product_categories && item.product_categories.length > 0) {
      // If there are no primary categories, use the first one
      const category = item.product_categories[0].category;

      categoriesMap[category.name] = {
        name: category.name,
        position: category.position ?? 99,
      };
    }
  });

  if (
    items.some((item) => item.type === 'deals' || item.type === 'custom_deals')
  ) {
    categoriesMap['Deals'] = { name: 'Deals', position: 0 };
  }

  if (items.some((item) => item.type === 'split_product')) {
    categoriesMap['Split Pizza'] = { name: 'Split Pizza', position: 0 };
  }

  if (
    !Object.keys(categoriesMap).length ||
    !items.some(
      (item) =>
        item.type === 'split_product' ||
        item.type === 'deals' ||
        item.type === 'custom_deals' ||
        item.product_categories,
    )
  ) {
    categoriesMap['Others'] = { name: 'Others', position: 999 };
  }

  const categories = Object.values(categoriesMap);

  // Sort categories by their position property
  categories.sort((a, b) => a.position - b.position);

  // Sort items by category
  let sortedItems = categories.reduce(
    (acc, category) => {
      acc[category.name] = items.filter((item) => {
        if (item.product_categories) {
          // handle primary categories
          if (!item.product_categories.some((cat) => cat.primary)) {
            return item.product_categories[0]?.category.name === category.name;
          }

          return item.product_categories.some(
            (cat) =>
              cat.primary &&
              cat.category &&
              cat.category.name === category.name,
          );
        }
        if (item.type === 'split_product') {
          return category.name === 'Split Pizza';
        }
        if (item.type === 'deals' || item.type === 'custom_deals') {
          return category.name === 'Deals';
        }
        return category.name === 'Others';
      });
      return acc;
    },
    {} as Record<string, OrderDetailType[]>,
  );

  // Handle duplication of items based on quantity if mode is 'order'
  if (mode === 'order') {
    sortedItems = Object.keys(sortedItems).reduce(
      (acc, category) => {
        acc[category] = sortedItems[category].flatMap((item) => {
          const itemCopies = Array.from({ length: item.quantity }, () => ({
            ...item,
            quantity: 1,
            total: item.total / item.quantity,
          }));
          return itemCopies;
        });
        return acc;
      },
      {} as Record<string, OrderDetailType[]>,
    );
  }

  return (
    <div
      style={{
        fontSize: '10px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '4px 7px 0',
      }}
    >
      {categories.map((category, index) => (
        <div key={index}>
          <SectionDivider name={category.name} index={index} key={index} />
          {sortedItems[category.name].length > 0 &&
            sortedItems[category.name].map((item, curIndex) =>
              renderItem(item, paid, mode, curIndex),
            )}
        </div>
      ))}
      {method !== 'dine-in' && !paid && (
        <>
          <div
            style={{
              borderBottom: '1px dashed #D4D4D4',
              padding: '1px',
              width: '100%',
            }}
          />
          <div
            style={{
              paddingTop: '4px',
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '10px',
              fontWeight: 400,
              width: '100%',
            }}
          >
            <div>Subtotal</div>
            <div>{paid ? '' : `${sub_total} kr`}</div>
          </div>
          {discount_total > 0 && (
            <div
              style={{
                paddingTop: '4px',
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '10px',
                fontWeight: 400,
                width: '100%',
              }}
            >
              <div>Discount</div>
              <div>{paid ? '' : `- ${discount_total} kr`}</div>
            </div>
          )}
          {tax_rates ? (
            tax_rates.map((tax, key) => {
              return (
                <div
                  style={{
                    paddingTop: '4px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontSize: '10px',
                    fontWeight: 400,
                    width: '100%',
                  }}
                  key={key}
                >
                  {/* VAT ARE SUPPOSED TO BE CHANGEABLE, CHANGE THIS LATER */}
                  <div>{tax.name}</div>
                  <div>{paid ? '' : `${tax.value} kr`}</div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                paddingTop: '4px',
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '10px',
                fontWeight: 400,
                width: '100%',
              }}
            >
              {/* VAT ARE SUPPOSED TO BE CHANGEABLE, CHANGE THIS LATER */}
              <div>VAT 11%</div>
              <div>{paid ? '' : `${tax_total} kr`}</div>
            </div>
          )}

          <div
            style={{
              paddingTop: '6px',
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '16px',
              fontWeight: 700,
              width: '100%',
            }}
          >
            <div>Total</div>
            <div>{paid ? '' : `${total} kr`}</div>
          </div>
        </>
      )}
    </div>
  );
};

export const Footer = () => (
  <div
    style={{
      fontSize: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      marginTop: '12px',
      padding: '14px',
      borderTop: '1px solid #D4D4D4',
    }}
  >
    <div
      style={{
        display: 'flex',
        columnGap: '8px',
        paddingBottom: '8px',
      }}
    >
      <div>Powered by</div>
      <BlackWithText />
    </div>
    <div>Þessi reikningur á uppruna sinn í rafrænu</div>
    <div>reikningakerfi skv. reglugerð nr. 505/2013</div>
  </div>
);
