import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from '../../store';
import { WidgetSettingType } from '../../../@types/v2/online-store/widgets';

// ----------------------------------------------------------------------

export type CustomWidgetSettingType = WidgetSettingType & {
  is_refresh?: boolean;
  is_new?: boolean;
};

type WidgetStateType = {
  isLoading: boolean;
  error: boolean;
  sortBy: string | null;
  data: CustomWidgetSettingType[];
  active: string[];
};

const initialState: WidgetStateType = {
  isLoading: false,
  error: false,
  sortBy: null,
  data: [],
  active: [],
};

// ----------------------------------------------------------------------

const widgetSlice = createSlice({
  name: 'widgets',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.data = [];
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setWidgets(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    setActiveWidgets(state, action) {
      state.active = action.payload;
    },
  },
});

export const useWidgets = () => useSelector((RootState) => RootState.widgets);

// Reducer
export default widgetSlice.reducer;

// Actions
export const { startLoading, hasError, setWidgets, setActiveWidgets } =
  widgetSlice.actions;
