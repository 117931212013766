import { useContext } from 'react';
import { AuthContext as PropelContext } from './propel-auth/PropelAuthContext';

const usePropelAuthContext = () => {
  const context = useContext(PropelContext);

  if (!context) {
    throw new Error('Auth context must be use inside AuthProvider');
  }

  return context;
};

export { usePropelAuthContext };
