import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import {
  BranchReportType,
  DealsReportType,
  FulfillmentReportType,
  HourlySalesTrendType,
  PerDateSalesTrendType,
  ProductsReportType,
  SummaryCustomerGrowthReportType,
  PerDateSourceReportType,
  BranchInsightReportType,
  HourlySourceReportType,
} from '../../@types/v2/reports';

// ----------------------------------------------------------------------

export type DashboardStateType = {
  error: boolean;
  salesTrend: {
    data: {
      hourly: {
        current: HourlySalesTrendType;
        previous: HourlySalesTrendType;
      };
      perDate: {
        current: PerDateSalesTrendType;
        previous: PerDateSalesTrendType;
      };
    };
    isLoading: boolean;
  };
  salesBySource: {
    data: {
      hourly: {
        current: HourlySourceReportType;
        previous: HourlySourceReportType;
      };
      perDate: {
        current: PerDateSourceReportType;
        previous: PerDateSourceReportType;
      };
    };
    isLoading: boolean;
  };

  deals: {
    data: DealsReportType;
    isLoading: boolean;
  };
  branch: {
    data: BranchReportType;
    isLoading: boolean;
  };
  branchInsight: {
    data: BranchInsightReportType;
    isLoading: boolean;
  };
  salesByProduct: {
    data: ProductsReportType;
    isLoading: boolean;
  };
  fulfillment: {
    data: FulfillmentReportType;
    isLoading: boolean;
  };
  hourlySales: {
    data: HourlySalesTrendType;
    isLoading: boolean;
  };
  customerGrowth: {
    data: {
      current: SummaryCustomerGrowthReportType;
      previous: SummaryCustomerGrowthReportType;
    };
    isLoading: boolean;
  };
};

const initialState: DashboardStateType = {
  error: false,
  salesTrend: {
    data: {
      hourly: {
        current: {} as HourlySalesTrendType,
        previous: {} as HourlySalesTrendType,
      },
      perDate: {
        current: {} as PerDateSalesTrendType,
        previous: {} as PerDateSalesTrendType,
      },
    },
    isLoading: false,
  },
  salesBySource: {
    data: {
      hourly: {
        current: {} as HourlySourceReportType,
        previous: {} as HourlySourceReportType,
      },
      perDate: {
        current: {} as PerDateSourceReportType,
        previous: {} as PerDateSourceReportType,
      },
    },
    isLoading: false,
  },
  deals: {
    data: {} as DealsReportType,
    isLoading: false,
  },
  branch: {
    data: {} as BranchReportType,
    isLoading: false,
  },
  branchInsight: {
    data: {} as BranchInsightReportType,
    isLoading: false,
  },
  salesByProduct: {
    data: {} as ProductsReportType,
    isLoading: false,
  },
  fulfillment: {
    data: {} as FulfillmentReportType,
    isLoading: false,
  },
  hourlySales: {
    data: {} as HourlySalesTrendType,
    isLoading: false,
  },
  customerGrowth: {
    data: {
      current: {} as SummaryCustomerGrowthReportType,
      previous: {} as SummaryCustomerGrowthReportType,
    },
    isLoading: false,
  },
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state, action) {
      switch (action.payload) {
        case 'sales-trend':
          state.salesTrend.isLoading = true;
          break;
        case 'branch':
          state.branch.isLoading = true;
          break;
        case 'branch-insight':
          state.branchInsight.isLoading = true;
          break;
        case 'sales-by-product':
          state.salesByProduct.isLoading = true;
          break;
        case 'fulfillment':
          state.fulfillment.isLoading = true;
          break;
        case 'activity':
          state.hourlySales.isLoading = true;
          break;
        case 'growth':
          state.customerGrowth.isLoading = true;
          break;
        case 'deals':
          state.deals.isLoading = true;
          break;
        case 'sales-by-source':
          state.salesBySource.isLoading = true;
          break;
        default:
          break;
      }
    },

    // HAS ERROR
    hasError(state, action) {
      state.salesByProduct.isLoading = false;
      state.fulfillment.isLoading = false;
      state.deals.isLoading = false;
      state.hourlySales.isLoading = false;
      state.customerGrowth.isLoading = false;
      state.salesTrend.isLoading = false;
      state.branch.isLoading = false;
      state.branchInsight.isLoading = false;
      state.salesBySource.isLoading = false;
      state.error = action.payload;
    },

    // Set Sales Trend
    setDashboardSalesTrend(
      state,
      action: {
        payload: {
          current: HourlySalesTrendType | PerDateSalesTrendType;
          previous: HourlySalesTrendType | PerDateSalesTrendType;
          isHourly: boolean;
        };
      },
    ) {
      const { current, previous, isHourly } = action.payload;
      const { hourly, perDate } = state.salesTrend.data;
      if (isHourly) {
        hourly.current = current as HourlySalesTrendType;
        hourly.previous = previous as HourlySalesTrendType;
      } else {
        perDate.current = current as PerDateSalesTrendType;
        perDate.previous = previous as PerDateSalesTrendType;
      }
      state.salesTrend.isLoading = false;
    },

    setDashboardSalesBySource(
      state,
      action: {
        payload: {
          current: HourlySourceReportType | PerDateSourceReportType;
          previous: HourlySourceReportType | PerDateSourceReportType;
          isHourly: boolean;
        };
      },
    ) {
      const { current, previous, isHourly } = action.payload;
      const { hourly, perDate } = state.salesBySource.data;

      if (isHourly) {
        hourly.current = current as HourlySourceReportType;
        hourly.previous = previous as HourlySourceReportType;
      } else {
        perDate.current = current as PerDateSourceReportType;
        perDate.previous = previous as PerDateSourceReportType;
      }
      state.salesBySource.isLoading = false;
    },

    // set Deals
    setDashboardDeals(state, action) {
      state.deals.isLoading = false;
      state.deals.data = action.payload;
    },

    // Set Branch
    setDashboardBranch(state, action) {
      state.branch.isLoading = false;
      state.branch.data = action.payload;
    },

    // Set Branch Insight
    setDashboardBranchInsight(state, action) {
      state.branchInsight.isLoading = false;
      state.branchInsight.data = action.payload;
    },

    // Set Sales By Product
    setDashboardSalesByProduct(state, action) {
      state.salesByProduct.isLoading = false;
      state.salesByProduct.data = action.payload;
    },

    // set booking activity
    setDashboardHourlySales(state, action) {
      state.hourlySales.isLoading = false;
      state.hourlySales.data = action.payload;
    },

    // set customer growth
    setDashboardCustomerGrowth(
      state,
      action: {
        payload: {
          current: SummaryCustomerGrowthReportType;
          previous: SummaryCustomerGrowthReportType;
        };
      },
    ) {
      const { current, previous } = action.payload;
      state.customerGrowth.isLoading = false;
      state.customerGrowth.data.current = current;
      state.customerGrowth.data.previous = previous;
    },

    setDashboardFulfillment(state, action) {
      state.fulfillment.isLoading = false;
      state.fulfillment.data = action.payload;
    },
  },
});

export const useDashboard = (): DashboardStateType =>
  useSelector(
    (RootState: { dashboard: DashboardStateType }) => RootState.dashboard,
  );

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  setDashboardBranch,
  setDashboardBranchInsight,
  setDashboardCustomerGrowth,
  setDashboardFulfillment,
  setDashboardSalesByProduct,
  setDashboardDeals,
  setDashboardSalesTrend,
  setDashboardHourlySales,
  setDashboardSalesBySource,
} = slice.actions;
