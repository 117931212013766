import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from '../../../store';
import { ProductType } from '../../../../@types/v2/products';
import { DealType } from '../../../../@types/v2/deals';
import {
  CreateOrderFormProps,
  CartItemOrder,
} from '../../../../validations/orders/create-order';
import { applyDeals } from './deal_dialog';
import { applyProduct } from './pizza_dialog';

// ----------------------------------------------------------------------

type ProductDialogStateType = {
  activeType:
    | 'simple'
    | 'variation'
    | 'deals'
    | 'pizza'
    | 'simple_extras'
    | 'variation_extras'
    | null;
  selectedProduct: any | null;
  isOpen: boolean;
  cartTempOpen: boolean;
  items: Array<any>;
  filterCategory: string[];
};

const initialState: ProductDialogStateType = {
  selectedProduct: null,
  activeType: null,
  cartTempOpen: false,
  isOpen: false,
  items: [],
  filterCategory: [],
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'product-dialog',
  initialState,
  reducers: {
    openDialog: (state) => {
      state.isOpen = true;
    },
    closeDialog: (state) => {
      state.isOpen = false;
      state.activeType = null;
      state.selectedProduct = null;
      state.items = [];
      state.cartTempOpen = false;
    },
    applyItems: (
      state,
      action: PayloadAction<CreateOrderFormProps['items']>,
    ) => {
      state.items = action.payload;
    },
    previous: (state) => {
      state.activeType = null;
    },
    setActiveType: (
      state,
      { payload }: PayloadAction<ProductDialogStateType['activeType']>,
    ) => {
      state.activeType = payload;
    },
    setSelectedProduct: (
      state,
      { payload }: PayloadAction<ProductDialogStateType['selectedProduct']>,
    ) => {
      state.selectedProduct = payload;
    },
    addItemToTemp: (state, action: PayloadAction<CartItemOrder>) => {
      const addToTemp = (items: CartItemOrder[], product: CartItemOrder) => {
        const isProductInTemp = items.find((item) => item.ref === product.ref);

        // If added product is not in temp, then add it to the temp
        if (!isProductInTemp) {
          return [...items, product];
        }

        // If product already in temp and is a pizza, then overwrite the previous pizza selection with the new one. (edit pizza)
        if (isProductInTemp && product.type === 'pizza') {
          return items.map((item) => {
            if (item.ref === product.ref) {
              item = product;
            }
            return item;
          });
        }

        // If added product is already in temp do not have any variation (e.g. simple product), then add the quantity in temp
        if (isProductInTemp) {
          return items.map((item) => {
            if (item.ref === product.ref) {
              item = product;
            }
            return item;
          });
        }

        return [...items, product];
      };

      state.items = addToTemp(state.items, action.payload);
    },
    setCartTempOpen: (state, action: PayloadAction<boolean>) => {
      state.cartTempOpen = action.payload;
    },
    deleteItem: (
      state,
      action: PayloadAction<{
        item: CartItemOrder;
      }>,
    ) => {
      const { items } = state;
      const { item: itemToDelete } = action.payload;

      const index = items.findIndex((item) => item.ref === itemToDelete.ref);

      if (index === -1) {
        return;
      }

      const newItems = [...items];
      newItems.splice(index, 1);
      state.items = newItems;
    },
    increaseQuantity: (
      state,
      action: PayloadAction<{
        item: any;
      }>,
    ) => {
      const { items } = state;
      const { item } = action.payload;

      const index = items.findIndex((tempItem) => tempItem.ref === item.ref);

      if (index === -1) {
        return;
      }

      const newItems = [...items];

      newItems[index].quantity += 1;

      state.items = newItems;
    },
    decreaseQuantity: (
      state,
      action: PayloadAction<{
        item: any;
      }>,
    ) => {
      const { items } = state;
      const { item } = action.payload;

      const index = items.findIndex((tempItem) => tempItem.ref === item.ref);

      if (index === -1) {
        return;
      }

      const newItems = [...items];

      if (newItems[index].quantity === 1) {
        newItems.splice(index, 1);
      } else {
        newItems[index].quantity -= 1;
      }

      state.items = newItems;
    },
    setFilterCategory: (state, action: PayloadAction<string[]>) => {
      state.filterCategory = action.payload;
    },
    reset: () => initialState,
  },
});

export const getProductCategory = (product: ProductType) => {
  if (!product || !product.categories.length) {
    return null;
  }

  const primary = product.categories.find((category) => category.primary);

  if (!primary) {
    return product.categories[0].category;
  }

  return primary.category;
};

export const useProductDialog = () => {
  const state = useSelector((store) => store.productDialog);
  const dispatch = useDispatch();

  const calculatePrice = () => {
    if (!state.items.length) {
      return 0;
    }

    return state.items.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0,
    );
  };

  const selectProductFromList = (product: ProductType) => {
    const category = getProductCategory(product);

    if (!category) {
      return;
    }

    if (product.type === 'simple_extras') {
      dispatch(setActiveType('simple_extras'));
      dispatch(
        applyProduct({
          side: 'left',
          productData: product,
        }),
      );
      return;
    }

    if (product.type === 'variation_extras') {
      dispatch(setActiveType('pizza'));
      dispatch(
        applyProduct({
          side: 'left',
          productData: product,
        }),
      );
      return;
    }

    if (category.name === 'Deals') {
      return;
    }

    if (!product.variations.length) {
      alert('The product has no variations');
      return;
    }

    dispatch(setActiveType('variation'));
    dispatch(setSelectedProduct(product));
  };

  const selectDealFromList = (deal: DealType) => {
    dispatch(setActiveType('deals'));
    dispatch(applyDeals(deal));
  };

  return {
    ...state,
    selectProductFromList,
    calculatePrice,
    selectDealFromList,
  };
};

// Reducer
export default slice.reducer;

// Actions
export const {
  reset,
  openDialog,
  setActiveType,
  closeDialog,
  previous,
  addItemToTemp,
  increaseQuantity,
  decreaseQuantity,
  setSelectedProduct,
  deleteItem,
  applyItems,
  setCartTempOpen,
  setFilterCategory,
} = slice.actions;
