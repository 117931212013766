import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from '../../../store';
import { OrderType } from '../../../../@types/v2/orders/list';

// ----------------------------------------------------------------------

type SuccessModalStateType = {
  open: boolean;
  order: OrderType | null;
  error: string | null;
};

const initialState = <SuccessModalStateType>{
  open: false,
  order: null,
  error: null,
};

// ----------------------------------------------------------------------

const successModalSlice = createSlice({
  name: 'success-modal',
  initialState,
  reducers: {
    openSuccessModal: (state, action) => {
      state.open = true;
      state.order = action.payload;
      state.error = null;
    },
    closeSuccessModal: (state) => {
      state.open = false;
      state.order = null;
      state.error = null;
    },
    openErrorModal: (state, action) => {
      state.open = true;
      state.order = null;
      state.error = action.payload;
    },
    closeErrorModal: (state) => {
      state.open = false;
      state.order = null;
      state.error = null;
    },
  },
});

export const useSuccessModal = () => useSelector((state) => state.successModal);

export default successModalSlice.reducer;

export const {
  openSuccessModal,
  closeSuccessModal,
  openErrorModal,
  closeErrorModal,
} = successModalSlice.actions;
