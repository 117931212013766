import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from '../../store';
import { TaxType } from '../../../@types/v2/settings/taxes';

// ----------------------------------------------------------------------

type TaxStateType = {
  loading: {
    main: boolean;
    activation: boolean;
  };
  error: boolean;
  taxes: TaxType[];
  currentTax: TaxType | null;
};

const initialState: TaxStateType = {
  loading: {
    main: false,
    activation: false,
  },
  error: false,
  taxes: [],
  currentTax: null,
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'taxes',
  initialState,
  reducers: {
    startLoading(state, action) {
      switch (action.payload) {
        case 'main':
          state.loading.main = true;
          break;
        case 'activation':
          state.loading.activation = true;
          break;
        default:
          break;
      }
    },
    stopLoading(state, action) {
      switch (action.payload) {
        case 'main':
          state.loading.main = false;
          break;
        case 'activation':
          state.loading.activation = false;
          break;
        default:
          break;
      }
    },
    hasError(state, action) {
      state.loading.main = false;
      state.error = action.payload;
    },
    setTaxes(state, action) {
      state.loading.main = false;
      state.loading.activation = false;
      state.taxes = action.payload;
    },

    setCurrentTax(state, action) {
      state.loading.main = false;
      state.currentTax = action.payload;
    },
  },
});

export const useTaxes = (): TaxStateType =>
  useSelector((RootState: { taxes: TaxStateType }) => RootState.taxes);

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, hasError, setTaxes, setCurrentTax } =
  slice.actions;
