import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import {
  MediaLibraryFileType,
  MediaLibraryPagination,
} from '../../@types/v2/media-library';

// ----------------------------------------------------------------------

type MediaLibraryStateType = {
  isLoading: boolean;
  error: boolean;
  files: MediaLibraryFileType[];
  pagination: MediaLibraryPagination | null;
};

const initialState: MediaLibraryStateType = {
  isLoading: false,
  error: false,
  files: [],
  pagination: null,
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'media-library',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET ALL MEDIA FILES
    setMediaLibraryFiles(state, action) {
      state.isLoading = false;
      state.files = action.payload;
    },

    // SET PAGINATION
    setPagination(state, action) {
      state.pagination = action.payload;
    },
  },
});

export default slice.reducer;

export const useMediaLibrary = (): MediaLibraryStateType =>
  useSelector(
    (RootState: { mediaLibrary: MediaLibraryStateType }) =>
      RootState.mediaLibrary,
  );

export const {
  startLoading,
  setPagination,
  stopLoading,
  hasError,
  setMediaLibraryFiles,
} = slice.actions;
