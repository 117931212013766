import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { IntegrationType } from '../../@types/v2/integration';

// ----------------------------------------------------------------------

type IntegrationStateType = {
  error: boolean;
  isLoading: boolean;
  data: IntegrationType[];
};

const initialState: IntegrationStateType = {
  error: false,
  isLoading: false,
  data: [],
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setIntegration(state, action) {
      state.isLoading = false;
      state.data = action.payload.sort(
        (a: Partial<IntegrationType>, b: Partial<IntegrationType>) =>
          (a.name || '').localeCompare(b.name || ''),
      );
    },
  },
});

export const useIntegrations = (): IntegrationStateType =>
  useSelector(
    (RootState: { integrations: IntegrationStateType }) =>
      RootState.integrations,
  );

// Actions
export const { startLoading, stopLoading, hasError, setIntegration } =
  slice.actions;

// Reducer
export default slice.reducer;
