// disabled for use with ZPL
import { format } from 'date-fns';
import type {
  ExtrasType,
  OrderDetailType,
  OrderType,
} from '../../../@types/v2/orders/list';

export type LabelSettingsType = {
  LABEL_PRINT_SKU: boolean;
};

const variationExtras = (item: OrderDetailType, xOffset = 0) => {
  // split item.variation.name by (,)
  const variations: string[] = item.variation.name.split(',');

  const hasRoasted = variations.length === 3;

  return `
      ^CF0,30
      ^FO0,0^FB352,2,,C^FD${item.name.toUpperCase()}\\&^FS
      ^FO60,0^GB1,352,1^FS
      ^FO65,0^GB3,352,3^FS

      ^CF0,25
      ^FO90,176^FB176,,,C^FD${variations[0].toUpperCase()}\\&^FS
      ${
        hasRoasted
          ? `
        ^FO85,0^FB176,,,C^FD${variations[1].slice(1).toUpperCase()}\\&^FS
        ^CF0,20
        ^FO110,0^FB176,,,C^FD${variations[2].slice(1).toUpperCase()}\\&^FS
      `
          : `
        ^FO90,0^FB176,,,C^FD${variations[1].slice(1).toUpperCase()}\\&^FS
      `
      }
      ^FO65,176^GB70,3,3^FS
      ^FO135,0^GB3,352,3^FS
      ${displayVariationExtras(item.extras)}
    `;
};

const CATEGORIES: { [key: string]: string } = {
  '663d83ef6084a5526c730211': 'Vefjur',
  '663b860e0e3645ec1c61b2ba': 'Salöt',
};

const simpleExtras = (item: OrderDetailType, xOffset = 0) => {
  const categoryId = () => {
    if (typeof item.product_categories[0].category === 'string') {
      return item.product_categories[0].category as string;
    } else if ('_id' in item.product_categories[0].category) {
      return item.product_categories[0].category._id as string;
    } else {
      return '';
    }
  };

  const categoryName = CATEGORIES[categoryId()] || '';

  return `
      ^CF0,30
      ^FO0,0^FB352,2,,C^FD${item.name.toUpperCase()}\\&^FS
      ^FO60,0^GB1,352,1^FS
      ^FO65,0^GB3,352,3^FS

      ^CF0,25
      ^FO90,176^FB176,,,C^FD${categoryName.toUpperCase()}\\&^FS

      ^FO65,176^GB70,3,3^FS
      ^FO135,0^GB3,352,3^FS
  `;
};

const EXTRAS_DATA = [
  {
    name: 'Kjötálegg',
    maxLineValues: 9,
    maxHeight: 180,
    maxItems: 12,
  },
  {
    name: 'Kjöt',
    maxLineValues: 10,
    maxHeight: 200,
    maxItems: 12,
  },
  {
    name: 'Grænmeti',
    maxLineValues: 9,
    maxHeight: 180,
    maxItems: 12,
  },
  {
    name: 'Sósur',
    maxLineValues: 12,
    maxHeight: 240,
    maxItems: 14,
  },
  {
    name: 'Krydd',
    maxLineValues: 4,
    maxHeight: 80,
    maxItems: 4,
  },
];
const displayVariationExtras = (
  extras: ExtrasType[],
  xOffset = 0,
  yOffset = 0,
) => {
  const START_Y = 145;
  const SPACE_TO_BORDER = 5;
  const SPACE_BETWEEN_EXTRAS = 5;
  let Y_TEMP = START_Y;

  const dataString: string[] = extras.map((extra, index) => {
    const extraItems = () => {
      const temp: string[] = [];

      // get excluded items
      if (extra.excluded_items.length > 0) {
        extra.excluded_items.forEach((item) => {
          if (item.include) {
            temp.push(
              `-${item.doubled ? '_C2_A0x2_C2_A0' : '_C2_A0'}${item.name}`,
            );
          }
        });
      }

      // get included items
      if (extra.items.length > 0) {
        extra.items.forEach((item) => {
          if (!item.include || item.doubled) {
            temp.push(
              `+${item.doubled ? '_C2_A0x2_C2_A0' : '_C2_A0'}${item.name}`,
            );
          }
        });
      }

      return temp;
    };

    const itemsArray = extraItems();

    if (itemsArray.length === 0) {
      return '';
    }

    const extrasData = EXTRAS_DATA.find((data) => data.name === extra.name);

    if (!extrasData) {
      return '';
    }

    let height = extrasData.maxHeight;
    let lines = extrasData.maxLineValues;

    if (itemsArray.length < extrasData.maxItems / 4) {
      height = extrasData.maxHeight / 4;
      lines = extrasData.maxLineValues / 4;
    } else if (itemsArray.length < extrasData.maxItems / 2) {
      height = extrasData.maxHeight / 2;
      lines = extrasData.maxLineValues / 2;
    }

    const yValue = Y_TEMP;
    const yBorder = yValue + height + SPACE_TO_BORDER;

    Y_TEMP += height + SPACE_TO_BORDER + SPACE_BETWEEN_EXTRAS;

    return `
        ^CF0,20
        ^FO${yValue},0^FB332,,,L^FD${extra.name.toUpperCase()}:\\&^FS
        ^FO${yValue},20^FB202,${lines},,L^FH^FD${itemsArray.join(', ').toUpperCase()}\\&^FS
        ^FO${yBorder},20^GB1,312,1^FS
      `;
  });

  return dataString.join('');
};

export const head = (item: OrderDetailType) => {
  // top padding
  const xOffset = 40;

  switch (item.type) {
    case 'variation_extras':
      return variationExtras(item, xOffset);
    default:
      return simpleExtras(item, xOffset);
  }
};

export const tail = (order: OrderType, index: number, length: number) => {
  const orderNumber = order.secondary_order_number
    ? order.secondary_order_number
    : `#${order.order_number}`;

  return `
  ^FO765,0^GB1,352,1^FS
  ^FO770,0^GB3,352,3^FS
  ^CF0,35
  ^FO790,20^FB312,,,L^FD${orderNumber}\\&^FS
  ^CF0,20
  ^FO830,20^FB312,,,L^FD${format(new Date(order.createdAt), 'dd-MM-yy')}, ${format(
    new Date(order.createdAt),
    'HH:mm',
  )}\\&^FS
  ^CF0,35
  ^FO790,20^FB332,,,R^FD${index + 1} OF ${length}\\&^FS
  `;
};
