import crypto from 'crypto';
import { z } from 'zod';
import {
  FullPermissions,
  DashboardPermissions,
  AdminRole,
  ADMIN_ROLES,
} from '@monorepo/upsell/roles';
import { RoleType } from '../@types/v2/roles';
import { OrgIdToOrgInfoType, OrgInfoType } from '../@types/v2/team-management';
import { PATH_DASHBOARD } from '../routes/paths';
import { AuthUserType } from './types/auth';

const algorithm = 'aes-256-cbc';
const pinKey = process.env.NEXT_PUBLIC_PIN_ENCRYPTION_KEY;

const decryptPIN = (text: string) => {
  if (!text || pinKey === undefined) {
    return '';
  }

  const [iv, encryptedText] = text
    .split(':')
    .map((part) => Buffer.from(part, 'hex'));
  const decipher = crypto.createDecipheriv(
    algorithm,
    Buffer.from(pinKey, 'hex'),
    iv,
  );

  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);

  return decrypted.toString('utf-8');
};

const SessionDataSchema = z.object({
  activeOrgName: z.string().nullable(),
  activeStore: z.string().nullable(),
  globalBranchIds: z.array(z.string()).nullable(),
});

type SessionData = z.infer<typeof SessionDataSchema>;

/**
 * Asynchronously sets or clears session data in localStorage.
 * @param data - The session data to set, or null to clear the session.
 * @returns A promise that resolves when the operation is complete.
 * @throws If there's an error during the operation.
 */
const setSessionAsync = async (data: SessionData | null): Promise<void> => {
  try {
    if (data === null) {
      localStorage.clear();
      return;
    }

    const validatedData = SessionDataSchema.parse(data);

    const sessionItems = [
      ['activeOrgName', validatedData.activeOrgName],
      ['activeStore', validatedData.activeStore],
      [
        'globalBranchIds',
        validatedData.globalBranchIds
          ? JSON.stringify(validatedData.globalBranchIds)
          : null,
      ],
    ] as const;

    sessionItems.forEach(([key, value]) => {
      if (value !== null) {
        localStorage.setItem(key, value);
      } else {
        localStorage.removeItem(key);
      }
    });
  } catch (error) {
    console.error('Error setting session data:', error);
    throw error;
  }
};

const setSession = (accessToken: string | null) => {
  const sessionData = {
    accessToken,
  };

  Object.entries(sessionData).forEach(([key, value]) => {
    if (value) {
      localStorage.setItem(key, value);
    } else {
      localStorage.removeItem(key);
    }
  });
};

const getRoleData = (user: AuthUserType): RoleType | null => {
  if (!user) {
    return null;
  }

  const selectedStore = user.stores.find(
    (curStore: { primary: boolean }) => curStore.primary,
  );
  if (!selectedStore) {
    return null;
  }

  const role = user.roles.find(
    (curRole: { store: string }) => curRole.store === selectedStore.store._id,
  );
  if (!role) {
    return null;
  }
  return role.role;
};

const getActiveOrg = async (
  orgIdToOrgInfo: OrgIdToOrgInfoType,
  storeId: string,
): Promise<OrgInfoType | null> => {
  const activeOrg = Object.values(orgIdToOrgInfo).find(
    (org) => org.orgMetadata.store_id === storeId,
  );

  return activeOrg || null;
};

const redirectToKDS = (
  currentPath: string,
  userPermissions: FullPermissions[],
): string | null => {
  const redirect =
    userPermissions.includes(DashboardPermissions.OrderDisplayRead) &&
    !userPermissions.includes(DashboardPermissions.ReportsReadLimited);

  if (redirect && currentPath === PATH_DASHBOARD.general.app) {
    return PATH_DASHBOARD.orderDisplay.root;
  }
  return null;
};

const hasPermission = (
  requiredPermission: FullPermissions,
  userPermissions: FullPermissions[],
) => userPermissions.includes(requiredPermission);

const hasAllPermissions = (
  requiredPermissions: FullPermissions[],
  userPermissions: FullPermissions[],
) =>
  requiredPermissions.every((permission) =>
    userPermissions.includes(permission),
  );

const hasRoleHigherOrSame = (userRole: AdminRole, targetRole: AdminRole) => {
  const userRoleIndex = ADMIN_ROLES.indexOf(userRole);
  const targetRoleIndex = ADMIN_ROLES.indexOf(targetRole);
  return userRoleIndex <= targetRoleIndex;
};

const hasRoleHigher = (userRole: AdminRole, targetRole: AdminRole) => {
  const userRoleIndex = ADMIN_ROLES.indexOf(userRole);
  const targetRoleIndex = ADMIN_ROLES.indexOf(targetRole);
  return userRoleIndex < targetRoleIndex;
};

export {
  getActiveOrg,
  decryptPIN,
  getRoleData,
  redirectToKDS,
  setSessionAsync,
  setSession,
  hasPermission,
  hasAllPermissions,
  hasRoleHigherOrSame,
  hasRoleHigher,
};
