import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { OrderType, PaymentHistoryType } from '../../../@types/v2/orders/list';

// ----------------------------------------------------------------------
type OrderDetailsStateType = {
  isLoading: boolean;
  error: boolean;
  selectedOrderDetails: OrderType | null;
  isEditing: boolean;
  paymentHistory: PaymentHistoryType[];
};

const initialState: OrderDetailsStateType = {
  isLoading: false,
  error: false,
  selectedOrderDetails: null,
  isEditing: false,
  paymentHistory: [],
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'order-details',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // SELECT ORDER DETAIL
    setSelectedOrderDetails: (state, action) => {
      state.isLoading = false;
      state.selectedOrderDetails = action.payload;
    },
    setPaymentHistory: (state, action) => {
      state.paymentHistory = action.payload;
    },
    // SET EDITING
    setIsEditing: (state, action) => {
      state.isEditing = action.payload;
    },

    setExternalData: (state, action) => {
      if (!state.selectedOrderDetails) {
        return;
      }
      state.selectedOrderDetails = {
        ...state.selectedOrderDetails,
        data: action.payload,
      };
    },
  },
});

export const useOrderDetails = (): OrderDetailsStateType =>
  useSelector(
    (RootState: { orderDetails: OrderDetailsStateType }) =>
      RootState.orderDetails,
  );

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  stopLoading,
  hasError,
  setSelectedOrderDetails,
  setIsEditing,
  setExternalData,
  setPaymentHistory,
} = slice.actions;
