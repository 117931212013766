import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { CalendarPeriodType } from '../../../components/date-range-picker';
import {
  OrderStatusCountType,
  OrderType,
  OrdersTabType,
} from '../../../@types/v2/orders/list';

// ----------------------------------------------------------------------

export type OrderListStateType = {
  loading: {
    main: boolean;
    activation: boolean;
    csv: boolean;
    xlsx: boolean;
    posturinn: boolean;
  };
  error: boolean;
  isOpenCard: boolean;
  sortBy: string;
  isOpenAddress: boolean;
  defaultCalendar: {
    start: string | null;
    end: string | null;
    period: CalendarPeriodType;
  };
  filterCancelled: boolean;
  selectedOrderType: OrdersTabType;
  data: OrderType[];
  summary: {
    completedOrders: number;
    totalCollected: number;
    totalOrders: number;
    orderStatusCount: OrderStatusCountType;
  };
};

const initialState: OrderListStateType = {
  loading: {
    main: false,
    activation: false,
    csv: false,
    xlsx: false,
    posturinn: false,
  },
  error: false,
  isOpenAddress: false,
  isOpenCard: false,
  sortBy: 'createdAt-desc',
  selectedOrderType: 'All orders',
  defaultCalendar: {
    start: new Date().toISOString(),
    end: new Date().toISOString(),
    period: 'today',
  },
  filterCancelled: true,
  summary: {
    totalOrders: 0,
    completedOrders: 0,
    totalCollected: 0,
    orderStatusCount: {
      all: 0,
      order_received: 0,
      waiting_payment: 0,
      processing: 0,
      ready_to_ship: 0,
      ready_to_pickup: 0,
      on_delivery: 0,
      completed: 0,
      delivered: 0,
      refunded: 0,
      cancelled: 0,
    },
  },
  data: [] as OrderType[],
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'order-list',
  initialState,
  reducers: {
    // START LOADING
    startLoading: (state, action) => {
      switch (action.payload) {
        case 'main':
          state.loading.main = true;
          break;
        case 'activation':
          state.loading.activation = true;
          break;
        case 'csv':
          state.loading.csv = true;
          break;
        case 'xlsx':
          state.loading.xlsx = true;
          break;
        case 'posturinn':
          state.loading.posturinn = true;
          break;
        default:
          break;
      }
    },

    // STOP LOADING
    stopLoading: (state, action) => {
      switch (action.payload) {
        case 'main':
          state.loading.main = false;
          break;
        case 'activation':
          state.loading.activation = false;
          break;
        case 'csv':
          state.loading.csv = false;
          break;
        case 'xlsx':
          state.loading.xlsx = false;
          break;
        case 'posturinn':
          state.loading.posturinn = false;
          break;
        default:
          break;
      }
    },

    // HAS ERROR
    hasError: (state, action) => {
      state.loading.main = false;
      state.loading.activation = false;
      state.error = action.payload;
    },

    // SET ORDER TYPE
    setSelectedOrderType: (state, action) => {
      state.selectedOrderType = action.payload;
    },

    addOrder(state, action) {
      state.data = [action.payload, ...state.data];
    },

    // GET ALL ORDERS
    setOrders(state, action) {
      state.data = action.payload;
      state.loading.main = false;
    },

    // MERGE ORDERS
    mergeOrders(state, action) {
      state.data = [...state.data, ...action.payload];
      state.loading.main = false;
    },

    // SET DEFAULT CALENDAR
    setDefaultCalendar(
      state,
      action: { payload: OrderListStateType['defaultCalendar'] },
    ) {
      state.defaultCalendar = action.payload;
    },

    // SORT & FILTER ORDERS
    sortByOrder(state, action) {
      state.sortBy = action.payload;
    },

    // UPDATE ALL ORDERS
    addMoreOrders(state, action) {
      state.loading.main = false;
      state.data = [...state.data, ...action.payload];
    },

    // DELETE ORDERS
    deleteOrderSuccess(state, action) {
      state.loading.main = false;
      state.data = action.payload;
    },

    // DELETE ORDERS V2
    deleteOrder(state, action) {
      state.loading.main = false;
      state.data = state.data.filter((item) => item._id !== action.payload);
    },

    // OPEN MODAL
    openCard(state) {
      state.isOpenCard = true;
    },

    // CLOSE MODAL
    closeCard(state) {
      state.isOpenCard = false;
    },

    // OPEN MODAL
    openAddress(state) {
      state.isOpenAddress = true;
    },

    // CLOSE MODAL
    closeAddress(state) {
      state.isOpenAddress = false;
    },

    // SOCKET RELATED ACTIONS
    // APPEND ORDERS
    socketAppendOrder(
      state,
      action: {
        payload: OrderType;
      },
    ) {
      // put action.payload to the front of the array
      state.data.unshift(action.payload);
      // state.newOrders.newData.unshift(action.payload);
    },

    socketUpdateOrder(
      state,
      action: {
        payload: any;
      },
    ) {
      state.data = state.data.map((item) => {
        if (item._id === action.payload._id) {
          return action.payload;
        }

        return item;
      });
    },

    setFilterCancelled(state, action) {
      state.filterCancelled = action.payload;
    },

    setSummary(state, action) {
      state.summary = action.payload;
    },
  },
});

export const {
  startLoading,
  hasError,
  stopLoading,
  addMoreOrders,
  setSelectedOrderType,
  setFilterCancelled,
  openCard,
  closeCard,
  openAddress,
  closeAddress,
  setOrders,
  sortByOrder,
  socketAppendOrder,
  socketUpdateOrder,
  setSummary,
  deleteOrder,
  setDefaultCalendar,
  addOrder,
  mergeOrders,
} = slice.actions;

export const useOrderList = (): OrderListStateType =>
  useSelector(
    (RootState: { orderList: OrderListStateType }) => RootState.orderList,
  );

export default slice.reducer;
