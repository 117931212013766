import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { AccountantKeyType } from '../../../@types/v2/settings/accountant-keys';

// ----------------------------------------------------------------------

type AccountantKeyStateType = {
  isLoading: boolean;
  error: boolean;
  sortBy: string | null;
  data: AccountantKeyType[];
  accountantKey: AccountantKeyType | null;
};

const initialState: AccountantKeyStateType = {
  isLoading: false,
  error: false,
  sortBy: null,
  data: [] as AccountantKeyType[],
  accountantKey: null as AccountantKeyType | null,
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'accountant-keys',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET ALL ACCOUNTANT
    setAccountantKeyList(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    // GET ACCOUNTANT SINGLE
    setAccountantKey(state, action) {
      state.isLoading = false;
      state.accountantKey = action.payload;
    },
    // SORT ACCOUNTANT KEY
    sortByAccountantKey(state, action) {
      state.sortBy = action.payload;
    },
  },
});

export const useAccountantKeys = (): AccountantKeyStateType =>
  useSelector(
    (RootState: { accountantKeys: AccountantKeyStateType }) =>
      RootState.accountantKeys,
  );

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  stopLoading,
  hasError,
  setAccountantKeyList,
  setAccountantKey,
  sortByAccountantKey,
} = slice.actions;
