import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { CalendarPeriodType } from '../../../components/date-range-picker';
import { OrderType } from '../../../@types/v2/orders/list';

// ----------------------------------------------------------------------

type OrderOverviewStateType = {
  isLoading: boolean;
  isLoadingBranch: boolean;
  error: boolean;
  sortBy: null;
  defaultCalendar: {
    start: string;
    end: string;
    period: CalendarPeriodType;
  };
  data: OrderType[];
  branchOrders: OrderType[];
};

const initialState: OrderOverviewStateType = {
  isLoading: false,
  isLoadingBranch: false,
  error: false,
  sortBy: null,
  data: [] as OrderType[],
  defaultCalendar: {
    start: new Date().toISOString(),
    end: new Date().toISOString(),
    period: 'today',
  },
  branchOrders: [] as OrderType[],
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'order-overview',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // START LOADING BRANCH
    startLoadingBranch(state) {
      state.isLoadingBranch = true;
    },
    // STOP LOADING BRANCH
    stopLoadingBranch(state) {
      state.isLoadingBranch = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // SET ORDER OVERVIEW
    setOrderOverview(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },

    // SET BRANCH TICKET DATA
    setBranchOrders(state, action) {
      state.isLoadingBranch = false;
      state.branchOrders = action.payload;
    },

    // SORT TAG
    sortByOrderOverview(state, action) {
      state.sortBy = action.payload;
    },

    // SET DEFAULT DATE with start date and end date
    setDefaultCalendar(
      state,
      action: { payload: OrderOverviewStateType['defaultCalendar'] },
    ) {
      state.defaultCalendar = action.payload;
    },
  },
});

export const useOrderOverview = (): OrderOverviewStateType =>
  useSelector(
    (RootState: { orderOverview: OrderOverviewStateType }) =>
      RootState.orderOverview,
  );

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  startLoadingBranch,
  stopLoadingBranch,
  setDefaultCalendar,
  setBranchOrders,
  stopLoading,
  hasError,
  setOrderOverview,
  sortByOrderOverview,
} = slice.actions;
