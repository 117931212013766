import dynamic from 'next/dynamic';
import { BoxProps, Box } from '@mui/material';
import logo from '../../lotties/spinner.json';

const Lottie = dynamic(() => import('lottie-react'), { ssr: false });

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <Lottie loop autoplay animationData={logo} />
    </Box>
  );
}
