import { ReactNode, useEffect, useState } from 'react';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { usePropelAuthContext } from '../../auth/useAuthContext';

type GrowthBookProviderProps = {
  children: ReactNode;
};

export default function GrowthBookUpsellProvider({
  children,
}: GrowthBookProviderProps) {
  const { user, currentStoreId } = usePropelAuthContext();
  const [growthbook, setGrowthbook] = useState<GrowthBook | null>(null);

  useEffect(() => {
    if (user !== null) {
      const newGrowthbook = new GrowthBook({
        apiHost: 'https://cdn.growthbook.io',
        clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
        enableDevMode:
          process.env.NODE_ENV !== 'production' ||
          user?.email.email_address.endsWith('@upsell.is'),
      });
      setGrowthbook(newGrowthbook);
      newGrowthbook.loadFeatures();
    }
  }, [user]);

  useEffect(() => {
    if (!user) {
      return;
    }
    const store = user.stores.find((storeItem) => storeItem.primary);

    if (!store) {
      throw new Error('Missing primary store');
    }
    if (!growthbook) {
      return;
    }
    growthbook.setAttributes({
      id: user?._id,
      email: user?.email?.email_address,
      company: currentStoreId ?? store.store._id,
    });
  }, [user, currentStoreId, growthbook]);
  if (!growthbook) {
    return children;
  }
  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  );
}
